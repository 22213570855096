import {createSlice} from "@reduxjs/toolkit";
import clone from "just-clone";

const initialState = {
    salesEntityIds: null,
    compareEntityIds: null,
    isEditState: false,
    editData: [],
    editParentData: {},
    editSharesData: [],
    activeStrategy: null,
    forecastPeriod: null
};

export const editParentMenuSlice = createSlice({
    name: 'editParentMenu',
    initialState: initialState,
    reducers: {
        setSalesEntityIds: (state, action) => {
            state.isEditState = false;
            state.salesEntityIds = action.payload;
        },
        setForecastPeriod: (state, action) => {
            state.isEditState = true;
            state.forecastPeriod = action.payload;
        },
        removeSalesEntityId: () => {
            return initialState;
        },
        resetParentEditState: (state) => {
            state.isEditState = false;
            state.editData = [];
            state.editParentData = {};
            state.editSharesData = [];
        },
        setActiveStrategy: (state, action) => {
            state.isEditState = true;
            state.activeStrategy = action.payload;
        },
        resetActiveStrategy: (state) => {
            state.activeStrategy = null;
            if (Object.keys(state.editData).length === 0) {
                state.isEditState = false;
            }
        },
        updateParentEditState: (state, action) => {
            state.editData = action.payload.map(data => {
                let preparedData = clone(data);
                preparedData['id'] = data.key;
                preparedData['key'] = 'Forecast'
                return preparedData;
            });
            // const {salesPlanEntityIds, editData} = action.payload;
            state.isEditState = true;
            // state.editData[salesPlanEntityIds] = clone(editData);
        },
        updateParentEditParentState: (state, action) => {
            state.editParentData = action.payload.length > 0 ? action.payload[0].data : {}
            state.isEditState = true;
        },
        updateParentEditSharesState: (state, action) => {
            state.editSharesData = action.payload.map(data => {
                let preparedData = clone(data);
                preparedData['id'] = data.key;
                preparedData['key'] = 'Forecast'
                return preparedData;
            });
            // const {salesPlanEntityIds, editData} = action.payload;
            state.isEditState = true;
            // state.editData[salesPlanEntityIds] = clone(editData);
        },
        setCompareEntityIds: (state, action) => {
            state.compareEntityId = action.payload;
        },
    },
});

export const {
    setActiveStrategy,
    setForecastPeriod,
    resetActiveStrategy,
    setSalesEntityId,
    removeSalesEntityId,
    resetParentEditState,
    updateParentEditState,
    updateParentEditParentState,
    updateParentEditSharesState,
    setCompareEntityIds,
} = editParentMenuSlice.actions;
export default editParentMenuSlice.reducer;
