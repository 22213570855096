import {configureStore} from "@reduxjs/toolkit";
import debounce from "just-debounce";
import merge from "just-merge";
import extend from "just-extend";

import menuReducer from "./features/Menu/menuSlice";
import editMenuReducer from "./features/EditMenu/editMenuSlice";
import editParentMenuReducer from "./features/EditMenu/editParentMenuSlice";
import salesPlanGridReducer from "./features/SalesPlanGrid/salesPlanGridSlice";
import inventoryPlannerMappingReducer from "./features/EditMenu/InventoryPlannerMapping/InventoryPlannerMappingSlice";
import get from "just-safe-get";
import set from "just-safe-set";


const stateStorage = sessionStorage;
const storageKey = 'globalState';
const storeItems = [
    'salesPlanGrid.monthRange',
    'salesPlanGrid.showColumns',
];
const initialState = {
    salesPlanGrid: {
        comparePlanId: null,
        selectedRowKeys: [],
        monthRange: ['2021/09', '2022/12'],
        showColumns: [
            'product_image_url',
            'marketplace',
            'region',
            'product_title',
            'active_strategy',
        ],
    }
}

function readPersistedState() {
    const storedState = stateStorage.getItem(storageKey);
    if (storedState) {
        const parsedState = JSON.parse(stateStorage.getItem(storageKey));
        return extend(true, initialState, parsedState);
    }
}

function writePersistedState(state) {
    const storeData = extend(true, ...storeItems.map(item => {
        const obj = {};
        set(obj, item, get(state, item, {}));
        return obj;
    }));

    stateStorage.setItem(storageKey, JSON.stringify(storeData));
}

const store = configureStore({
    reducer: {
        menu: menuReducer,
        salesPlanGrid: salesPlanGridReducer,
        editMenu: editMenuReducer,
        editParentMenu: editParentMenuReducer,
        inventoryPlannerMapping: inventoryPlannerMappingReducer,
    },
    // preloadedState: readPersistedState(),
});

// store.subscribe(debounce(
//     () => writePersistedState(store.getState()),
//     500,
//     true,
//     true,
// ));

export default store;
