import {Popup} from "devextreme-react/popup";
import List from "devextreme-react/list";
import {useDispatch, useSelector} from "react-redux";
import {setShowColumns} from "./salesPlanGridSlice";


export default function ColumnChooser(props) {
    const dispatch = useDispatch();
    const showColumns = useSelector(state => state.salesPlanGrid.showColumns);

    const data = props.columns.map(col => {
        const {caption, key} = col;
        return {caption, key};
    });

    return (
        <Popup
            title={'Column chooser'}
            visible={props.visible}
            defaultHeight={450}
            defaultWidth={250}
            resizeEnabled={true}
            showCloseButton={true}
            shading={false}
            closeOnOutsideClick={true}
            onHiding={props.onHiding}
        >
            <List
                keyExpr={'key'}
                displayExpr={'caption'}
                dataSource={data}
                selectionMode={'all'}
                showSelectionControls={true}
                selectedItemKeys={showColumns}
                onSelectedItemKeysChange={keys => {
                    setTimeout(() => {
                        const keysEqual = keys.length === showColumns.length
                            && keys.every(k => showColumns.includes(k));

                        if (!keysEqual) {
                            dispatch(setShowColumns(keys))
                        }
                    });
                }}
            />
        </Popup>
    )
}
