import {useEffect, useState} from "react";
import {Route, Switch, Redirect, HashRouter} from "react-router-dom";
import {Divider} from "@mui/material";
import {useQueryClient} from "react-query";
import {Provider} from "react-redux";
import clone from "just-clone"

import ForecastOverview from "./components/ForecastOverview.js";
import Login from "./components/App/Login.js";
import Menu from "./features/Menu/Menu.js";

import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {hasAccessToken, removeTokens} from "./api/auth";
import {SalesPlanInfoContext} from "./components/common/SalesPlanInfoContext";
import store from "./store";

import './App.css';
import {Popup} from "devextreme-react/popup";
import {useSalesPlansQuery, useSalesPlanIdUpdate} from "./api/useAPI";
import {useMutateSalesPlan} from "./api/useSalesPlan";


function App() {
    const [isAuth, setAuth] = useState(false);
    const [salesPlanId, setSalesPlanId] = useState();
    const [asinLevel, setAsinLevel] = useState(null);
    const queryClient = useQueryClient();
    const [isChangeStatusPopupVisible, setChangeStatusPopupVisible] = useState(false);
    const {data, refetch} = useSalesPlansQuery({notifyOnChangeProps: ['data']});
    const [currentSalesPlan, setCurrentSalesPlan] = useState(null);
    const [nextStatus, setNextStatus] = useState();

    const {saveEditData} = useMutateSalesPlan(salesPlanId);

    const logout = () => {
        setAuth(false);
        removeTokens();
    }

    const changeDescription = {
        'IN PROGRESS': [
            'Status changes to "IN PROGRESS": Predictions are ready for use but can still be edited',
            'Predictions are exported to tools like the Replenishment Tool for processing',
            'Status cannot revert to "NEW". It can only move forward to "APPROVED"',
        ],
        'APPROVED': [
            'Status changes to "APPROVED": Predictions are finalized and can no longer be modified',
            'Predictions are sent to external tools like the Replenishment Tool',
            'Status cannot revert to "NEW" or "IN PROGRESS"',
            'A new sales plan for the next month will be automatically generated',
        ]
    }

    queryClient.setDefaultOptions({
        queries: {
            onError: e => {
                if ([400, 404].includes(e?.response?.status)) {
                    logout();
                }
            },
        },
        mutations: {
            onError: e => {
                if ([400, 404].includes(e?.response?.status)) {
                    logout();
                }
            },
        }
    });


    useEffect(() => {
        const token = hasAccessToken();
        if (token && !isAuth) {
            setAuth(true);
        } else if (!token && isAuth) {
            setAuth(false);
        }
    });

    useEffect(() => {
        if (data) {
            const currentPlan = data.find(p => p.id === salesPlanId) || null;
            setCurrentSalesPlan(currentPlan);
        }
    }, [data, salesPlanId]);

    if (!isAuth) {
        return <Login onSuccess={() => setAuth(true)}/>
    }

    function handleSalesPlanChanged(e) {
        setChangeStatusPopupVisible(false);
        setSalesPlanId(e.value);
    }

    function handleAsinLevelChanged(e) {
        setAsinLevel(e.value)
    }

    function handleChangeSalesPlanStatusButtonClick(e) {
        if (e.id !== currentSalesPlan.id) {
            return
        }
        setNextStatus(e.status);
        setChangeStatusPopupVisible(true);
    }

    function changeSalesPlanStatus() {
        const updatedPlan = clone(currentSalesPlan)
        updatedPlan['status'] = nextStatus
        saveEditData(updatedPlan)
        setChangeStatusPopupVisible(false);
    }

    return (
        <SalesPlanInfoContext.Provider value={{id: salesPlanId}}>
            <Provider store={store}>
                <HashRouter>
                    <Menu
                        data={data}
                        refetch={refetch}
                        onLogout={logout}
                        onSalesPlanChanged={handleSalesPlanChanged}
                        onAsinLevelChanged={handleAsinLevelChanged}
                        onChangeSalesPlanStatusButtonClick={handleChangeSalesPlanStatusButtonClick}
                    />
                    <Popup
                        key={'change_sales_plan_status_dialog'}
                        width={700}
                        height={400}
                        showTitle={false}
                        visible={isChangeStatusPopupVisible}
                        toolbarItems={[{
                            toolbar: 'bottom',
                            location: 'center',
                            widget: 'dxButton',
                            options: {
                                text: 'Change status',
                                stylingMode: 'outlined',
                                onClick: changeSalesPlanStatus,
                            },
                        }, {
                            toolbar: 'bottom',
                            location: 'center',
                            widget: 'dxButton',
                            options: {
                                text: 'Cancel',
                                onClick: () => setChangeStatusPopupVisible(false),
                            },
                        }]}
                    >
                        <div style={{padding: '20px'}}>
                            <h2>
                                You are moving the status from "{currentSalesPlan?.status}" to "{nextStatus}"
                            </h2>
                            <div style={{marginTop: '15px'}}>
                                <b>Key changes:</b>
                                <ul>
                                    {(changeDescription[nextStatus] || []).map((description, index) => (
                                        <li key={index}>{description}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Popup>
                    <Divider/>
                    <Switch>
                        <Route path={'/forecast'}>
                            <ForecastOverview salesPlanId={salesPlanId} />
                        </Route>
                        <Route path={'/logout'}>
                            <Redirect to={'/'}/>
                        </Route>
                        <Route path={'/'}>
                            <div>Go to the forecasts page (choose "Forecast" in the menu in top-left corner)</div>
                        </Route>
                    </Switch>
                </HashRouter>
            </Provider>
        </SalesPlanInfoContext.Provider>
    )
}

export default App;
