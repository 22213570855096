import {createSlice} from "@reduxjs/toolkit";

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        salesPlanId: null,
        asinLevel: 'Child',
    },
    reducers: {
        selectSalesPlan: (state, action) => {
            state.salesPlanId = action.payload;
        },
        setAsinLevel: (state, action) => {
            state.asinLevel = action.payload;
        },
    },
});

export const {selectSalesPlan, setAsinLevel} = menuSlice.actions;
export default menuSlice.reducer;
