import {useQuery} from "react-query";
import {getInventoryPlannerAccountList, getVariants, getWarehouses} from "./api";


export function useIPAccounts(options) {
    return useQuery(['ip_accounts'], getInventoryPlannerAccountList, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 2*60*1000,
        ...options,
    });
}

export function useVariants(account_id, warehouse_id = null, options) {
    const key = [account_id, 'ip_variants'];
    if (warehouse_id !== null) {
        key.push(warehouse_id);
    }

    return useQuery(key, () => getVariants(account_id, warehouse_id), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 2*60*1000,
        ...options,
    });
}


export function useWarehouses(account_id, options) {
    return useQuery([account_id, 'warehouses'], () => getWarehouses(account_id), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 2*60*1000,
        ...options,
    })
}