import {createSlice} from "@reduxjs/toolkit";
import clone from "just-clone";

const initialState = {
    salesEntityId: null,
    salesEntityIds: null,
    compareEntityId: null,
    compareEntityIds: null,
    isEditState: false,
    editData: {},
    changedChildren: [],
    updatedChildren: [],
    editChildrenData: [],
    changedParent: {},
    editParentData: {},
    changedShares: [],
    editSharesData: [],
    activeStrategy: null,
    forecastPeriod: null,
    isParent: false
};

export const editMenuSlice = createSlice({
    name: 'editMenu',
    initialState: initialState,
    reducers: {
        setSalesEntityId: (state, action) => {
            state.isEditState = false;
            state.salesEntityId = action.payload;
        },
        setSalesEntityIds: (state, action) => {
            state.isEditState = false;
            state.salesEntityIds = action.payload;
        },
        setForecastPeriod: (state, action) => {
            state.isEditState = true;
            state.forecastPeriod = action.payload;
        },
        removeSalesEntityId: () => {
            return initialState;
        },
        resetEditState: (state) => {
            state.isEditState = false;
            state.editData = {};
            state.updatedChildren = [];
            state.changedChildren = [];
            state.editChildrenData = [];
            state.changedParent = {};
            state.editParentData = {};
            state.changedShares = [];
            state.editSharesData = [];
            state.activeStrategy = null;
            state.forecastPeriod = null;
        },
        setActiveStrategy: (state, action) => {
            state.isEditState = true;
            state.activeStrategy = action.payload;
        },
        resetActiveStrategy: (state) => {
            state.activeStrategy = null;
            if (Object.keys(state.editData).length === 0) {
                state.isEditState = false;
            }
        },
        updateChildren: (state, action) => {
            state.updatedChildren = action.payload;
        },
        updateEditState: (state, action) => {
            const {strategyType, editData} = action.payload;
            state.isEditState = true;
            state.editData[strategyType] = clone(editData);
        },
        setChangedChildren: (state, action) => {
            state.changedChildren = action.payload;
        },
        updateParentEditState: (state, action) => {
            state.editChildrenData = action.payload.map(data => {
                let preparedData = clone(data);
                preparedData['id'] = data.key;
                preparedData['key'] = 'Forecast'
                return preparedData;
            });
            // const {salesPlanEntityIds, editData} = action.payload;
            state.isEditState = true;
            // state.editData[salesPlanEntityIds] = clone(editData);
        },
        setChangedParent: (state, action) => {
            state.changedParent = action.payload;
        },
        updateParentEditParentState: (state, action) => {
            state.editParentData = action.payload.length > 0 ? action.payload[0].data : {}
            state.isEditState = true;
        },
        setChangedShares: (state, action) => {
            state.changedShares = action.payload;
        },
        updateParentEditSharesState: (state, action) => {
            state.editSharesData = action.payload.map(data => {
                let preparedData = clone(data);
                preparedData['id'] = data.key;
                preparedData['key'] = 'Forecast'
                return preparedData;
            });
            // const {salesPlanEntityIds, editData} = action.payload;
            state.isEditState = true;
            // state.editData[salesPlanEntityIds] = clone(editData);
        },
        setCompareEntityId: (state, action) => {
            state.compareEntityId = action.payload;
        },
        setCompareEntityIds: (state, action) => {
            state.compareEntityId = action.payload;
        },
        setIsParent: (state, action) => {
            state.isParent = action.payload;
        },
    },
});

export const {
    setActiveStrategy,
    resetActiveStrategy,
    setSalesEntityId,
    setSalesEntityIds,
    setForecastPeriod,
    removeSalesEntityId,
    resetEditState,
    updateEditState,
    updateChildren,
    updateParentEditState,
    updateParentEditParentState,
    updateParentEditSharesState,
    setChangedChildren,
    setChangedParent,
    setChangedShares,
    setCompareEntityIds,
    setCompareEntityId,
    setIsParent,
} = editMenuSlice.actions;
export default editMenuSlice.reducer;
