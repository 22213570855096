import pick from "just-pick";
import {formatNumber} from "devextreme/localization";

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}


export function formatForecastMonth(year, month) {
    if (!year || !month) {
        return null;
    } else {
        return `${year}/${month.toString().padStart(2, '0')}`;
    }
}


export function parseForecastMonth(str) {
    return str.split('/').map(i => parseInt(i));
}


export function forecastMonthToDate(str) {
    const [year, month] = parseForecastMonth(str);
    return new Date(year, month - 1, 1);
}


export class ProductsManager {
    constructor(products) {
        this.products = products;
        this.getProductById = this.getProductById.bind(this);
    }

    getProductById(product_id) {
        const filtered_products = this.products?.filter(p => p.id === product_id)
        if (filtered_products !== undefined) {
            return filtered_products[0];
        }
    }
}


export function removeEditButtonsFromToolbar(e) {
    e.toolbarOptions.items = e.toolbarOptions.items.filter(
        item => !['saveButton', 'revertButton', 'addRowButton'].includes(item.name)
    );
}


export function makeMonthRange(startDate, endDate) {
    // Day of startDate is ignored -- first month of range is always that of startDate
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const months = [];

    for (let year = startYear; year <= endYear; year++) {
        const curStartMonth = year === startYear ? startMonth : 0;
        const curEndMonth = year === endYear ? endMonth : 11;
        for (let month = curStartMonth; month <= curEndMonth; month++) {
            months.push({year, month: month + 1});
        }
    }
    return months;
}


export function removeOtherStrategies(dataSource, leaveStrategy) {
    dataSource.strategy_parameters = pick(dataSource.strategy_parameters, leaveStrategy) || {};
    dataSource.predictions.forEach(prediction => {
        prediction.strategy_parameters = pick(prediction.strategy_parameters, leaveStrategy) || {};
    });
}


export function getDeltaFormatter(percent = false) {
    const base_format = percent ? '+#,##0.#%;-#,##0.#%' : '+#,##0;-#,##0';

    return x => {
        if (!x && x !== 0) {
            return '-';
        } else if (Math.round(x) === 0 && !percent) {
            return '0';
        } else {
            return formatNumber(x, base_format);
        }
    }
}