import {Box, Item} from "devextreme-react/box";
import {ScrollView} from "devextreme-react";
import {SalesEntityInfo} from "./SalesEntityInfo";
import {SalesEntityChart} from "./SalesEntityChart";
import {useState} from "react";
import {List} from "devextreme-react/list";
import {Item as MultiViewItem, MultiView} from "devextreme-react/multi-view";
import InventoryPlannerMapping from "./InventoryPlannerMapping/InventoryPlannerMapping";


const menuItems = [
    {id: 0, text: 'Chart'},
    {id: 1, text: 'Inventory Planner'}
];


function renderListItem(data) {
    return (
        <div style={{writingMode: 'vertical-lr', padding: '5px 0px', transform: 'scale(-1)'}}>
            {data.text}
        </div>
    );
}


export default function InfoPanel({dataSource, ...props}) {
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    function handleMenuOptionChange(e) {
        if (e.name === 'selectedItems') {
            setSelectedMenuItem(e.value[0].id);
        }
    }

    return (
        <Box direction={'row'} height={'100%'} width={'100%'}>
            <Item baseSize={200} ratio={0}>
                <img
                    className={'edit-menu-product-image'}
                    src={dataSource?.product?.image_url}
                    alt={''}
                />
            </Item>
            <Item baseSize={400} ratio={0}>
                <ScrollView>
                    <SalesEntityInfo
                        product={dataSource?.product}
                        marketplace={dataSource?.marketplace}
                        region={dataSource?.region}
                        asins={dataSource?.asins}
                        skus={dataSource?.skus}
                    />
                </ScrollView>
            </Item>
            <Item baseSize={4} ratio={0}>
                <div style={{border: '1px solid lightgray', margin: '4px 2px', height: '100%'}}/>
            </Item>
            <Item baseSize={'50px'} ratio={0} shrink={0}>
                <List
                    className={'dx-vertical-list'}
                    dataSource={menuItems}
                    keyExpr={'id'}
                    displayExpr={'text'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    showSelectionControls={false}
                    selectedItemKeys={[selectedMenuItem]}
                    onOptionChanged={handleMenuOptionChange}
                    style={{paddingLeft: '10px'}}
                    itemRender={renderListItem}
                />
            </Item>
            <Item baseSize={400} ratio={1} shrink={0}>
                <MultiView
                    animationEnabled={false}
                    swipeEnabled={false}
                    selectedIndex={selectedMenuItem}
                    height={'100%'}
                    style={{padding: '20px 20px'}}
                >
                    <MultiViewItem>
                        <SalesEntityChart/>
                    </MultiViewItem>
                    <MultiViewItem>
                        <InventoryPlannerMapping data={dataSource}/>
                    </MultiViewItem>
                </MultiView>
            </Item>
        </Box>
    )
}