import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    accountId: null,
    warehouseId: null,
    variantId: null,
    defaultAccountId: null,
    defaultWarehouseId: null,
    defaultVariantId: null,
};


export const IPMappingSlice = createSlice({
    name: 'inventoryPlannerMapping',
    initialState: initialState,
    reducers: {
        setDefaults: (state, action) => {
            state.defaultAccountId = action.payload?.inventory_planner_account?.id;
            state.defaultWarehouseId = action.payload?.inventory_planner_warehouse;
            state.defaultVariantId = action.payload?.inventory_planner_product;

            state.accountId = action.payload?.inventory_planner_account?.id;
            state.warehouseId = action.payload?.inventory_planner_warehouse;
            state.variantId = action.payload?.inventory_planner_product;
        },
        setAccountId: (state, action) => {
            state.accountId = action.payload;
        },
        setWarehouseId: (state, action) => {
            state.warehouseId = action.payload;
        },
        setVariantId: (state, action) => {
            state.variantId = action.payload;
        },
        resetToDefaults: (state, action) => {
            state.accountId = state.defaultAccountId;
            state.warehouseId = state.defaultWarehouseId;
            state.variantId = state.defaultVariantId;
        },
    },
});


export function getIsIPMappingChanged({inventoryPlannerMapping: state}) {
    return (
        state.defaultAccountId !== state.accountId ||
        state.defaultWarehouseId !== state.warehouseId ||
        state.defaultVariantId !== state.variantId
    );
}


export const {
    setDefaults,
    resetToDefaults,
    setAccountId,
    setWarehouseId,
    setVariantId,
} = IPMappingSlice.actions;
export default IPMappingSlice.reducer;
