import jwtDecode from "jwt-decode";
import {handleHTTPErrors} from "./api";

const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';

const baseURL = process.env.REACT_APP_SERVER_URL;

export function hasAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export async function getAccessToken() {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken === null) {
        return;
    }

    if (Date.now() > jwtDecode(accessToken).exp * 1000) {
        accessToken = await refreshTokens();
    }

    return accessToken;
}

export function setTokens(serverReponse) {
    if (['access_token', 'refresh_token'].every(key => Object.keys(serverReponse).includes(key))) {
        localStorage.setItem(ACCESS_TOKEN_KEY, serverReponse.access_token);
        localStorage.setItem(REFRESH_TOKEN_KEY, serverReponse.refresh_token);
    }
}

export function removeTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

async function refreshTokens() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    const response = await fetch(`${baseURL}/auth/refresh`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: refreshToken}),
    }).then(handleHTTPErrors).then(r => r.json());

    setTokens(response);
    return response.access_token;
}

export async function sendGoogleToken(idToken) {
    return fetch(`${baseURL}/auth/login/google-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: idToken}),
    }).then(handleHTTPErrors).then(r => r.json()).then(r => setTokens(r))
}
