import {useRef, useEffect, useState} from "react";
import {useQueryClient} from "react-query";
import {RadioGroup} from "devextreme-react";
import Popup from "devextreme-react/popup";
import FileUploader from "devextreme-react/file-uploader";

import {baseURL} from "../../api/api";
import {getAccessToken} from "../../api/auth";
import {useRecalculateSalesPlanForecast, useSalesPlanIdQuery} from "../../api/useAPI";

import './UploadForecastMenu.css';

const uploadStateEnum = {
    init: 0,
    uploading: 1,
    updating: 2,
    updated: 3,
    error: 4,
};

const uploadStateMessage = {
    [uploadStateEnum.init]: null,
    [uploadStateEnum.uploading]: 'Uploading file to the server',
    [uploadStateEnum.updating]: 'Updating forecast, please wait',
    [uploadStateEnum.updated]: 'Forecast updated, you can upload new file or close this dialog',
    [uploadStateEnum.error]: 'Something went wrong',
}


function UploadForecastMenu(props) {
    const fileUploader = useRef();

    const [token, setToken] = useState(null);
    const [keepPrevious, setKeepPrevious] = useState(false);
    const [uploadState, setUploadState] = useState(uploadStateEnum.init);
    const [forecastType, setForecastType] = useState('manual');

    const queryClient = useQueryClient();
    const {isFetching} = useSalesPlanIdQuery(props.salesPlanId, {
        notifyOnChangeProps: ['isFetching'],
    });
    const {mutate: recalculate, isLoading: isRecalculating} = useRecalculateSalesPlanForecast(props.salesPlanId, {
      enabled: Boolean(props.salesPlanId) && !props.isLoading && !isFetching,
    });
    useEffect(() => {
        getAccessToken().then(
            (value, error) => {
                setToken(value);
            }
        )
    }, [props.visible]);

    useEffect(() => {
        if (!isFetching && uploadState === uploadStateEnum.updating) {
            setUploadState(uploadStateEnum.updated);
        }
    }, [isFetching]);

    const onBeforeSend = e => {
        setUploadState(uploadStateEnum.uploading);
    };

    const onUploadError = e => {
        e.message = 'Error: ' + e.error.responseText;
        setUploadState(uploadStateEnum.error);
    };

    const onSuccess = e => {
        recalculate()
        queryClient.invalidateQueries(['sales_plans', props.salesPlanId]);
        queryClient.invalidateQueries('sales_plan_entities');
        setUploadState(uploadStateEnum.updating);
    };

    const onHiding = () => {
        fileUploader.current.instance.reset();
        setUploadState(uploadStateEnum.init);
        props.onHiding();
    };
    const uploadParams = () => {
        if (forecastType === 'manual') {
          return (
            <div className={'dx-field'}>
              <div className={'dx-field-label field-label'}>Months that are missing from file</div>
              <div className={'dx-field-value'}>
                <RadioGroup
                  dataSource={[
                    {keepPrevious: false, text: 'Set to zero'},
                    {keepPrevious: true, text: 'Keep current forecast'}
                  ]}
                  displayExpr={'text'}
                  valueExpr={'keepPrevious'}
                  value={keepPrevious}
                  layout={'horizontal'}
                  onValueChange={value => setKeepPrevious(value)}
                />
              </div>
            </div>
          )
        }
    }
    const uploadUrl = () => {
        if (forecastType === 'manual')
          return `${baseURL}/import/manual_forecast/${props.salesPlanId}?keep_previous=${keepPrevious}`
        else if (forecastType === 'inherit')
          return `${baseURL}/import/inherit_forecast/${props.salesPlanId}`
    }
    return (
        <Popup
            title={'Upload forecast'}
            visible={props.visible}
            onHiding={onHiding}
            showCloseButton={!isFetching}
            width={600}
            height={360}
        >
            <div className={'dx-fieldset'}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label field-label'}>Choose forecast type</div>
                    <div className={'dx-field-value'}>
                      <RadioGroup
                        dataSource={[
                          {forecastType: 'manual', text: 'Manual'},
                          {forecastType: 'inherit', text: 'Inherit'}
                        ]}
                        displayExpr={'text'}
                        valueExpr={'forecastType'}
                        value={forecastType}
                        layout={'horizontal'}
                        onValueChange={value => setForecastType(value)}
                      />
                    </div>
                </div>
              {uploadParams()}
            </div>
            <FileUploader
                ref={fileUploader}
                uploadUrl={uploadUrl()}
                disabled={isFetching}
                allowCanceling={false}
                allowedFileExtensions={['.xlsx', '.xls']}
                name={'file'}
                uploadMode={'useButtons'}
                uploadHeaders={{
                    Authorization: 'Bearer ' + token,
                }}
                onBeforeSend={onBeforeSend}
                onUploaded={onSuccess}
                onUploadError={onUploadError}
            />
            <h4>{uploadStateMessage[uploadState]}</h4>
        </Popup>
    )
}


export default UploadForecastMenu;
