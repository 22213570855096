import {useHistory} from "react-router-dom";
import {Button} from "devextreme-react/button"
import {SelectBox} from "devextreme-react/select-box";
import {DropDownButton} from "devextreme-react/drop-down-button";
import {Item, Toolbar} from "devextreme-react/toolbar";
import {Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import {selectSalesPlan, setAsinLevel} from "./menuSlice";
import {deselectCompareSalesPlan} from "../SalesPlanGrid/salesPlanGridSlice";
import {useEffect, useState} from "react";

import '../../css/SalesPlanSelector.css';

const statusLevel = {
    NEW: 1,
    "IN PROGRESS": 2,
    APPROVED: 3,
};

const levelToStatus = Object.entries(statusLevel).reduce((acc, [status, level]) => {
    acc[level] = status;
    return acc
}, {})


function SalesPlanSelectorItem(data) {
    const statusColorMap = {
        NEW: 'success',
        "IN PROGRESS": 'secondary',
        APPROVED: 'primary',
    };

    return (
        <div className={'SalesPlanItem'}>
            {data?.title}
            <Chip
                label={data?.status}
                size={'small'}
                color={statusColorMap[data?.status]}
                variant={'outlined'}
                className={'SalesPlanStatus'}
            />
        </div>
    );
}


function Menu(props) {
    const [salesPlansList, setSalesPlansList] = useState();
    const [currentSalesPlanId, setCurrentSalesPlanId] = useState();
    const [nextStatus, setNextStatus] = useState();
    const [changeStatusButtonVisible, setChangeStatusButtonVisible] = useState(false)

    const {data, refetch, onLogout, onSalesPlanChanged, onChangeSalesPlanStatusButtonClick} = props;
    const history = useHistory();
    const redirectTo = (path) => history.push(path);

    const dispatch = useDispatch();
    const asinLevel = useSelector((state) => state.menu.asinLevel);

    useEffect(() => {
        if (data) {
            setSalesPlansList(
                [...data].sort((a, b) => (
                    a.status === b.status
                        ? (a.product_group.id === b.product_group.id
                            ? (b.from_year * 100 + b.from_month) - (a.from_year * 100 + a.from_month)
                            : a.title.localeCompare(b.title))
                        : statusLevel[a.status] - statusLevel[b.status]
                ))
            );
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const currentSalesPlanData = data.find(x => x.id === currentSalesPlanId)
            if (currentSalesPlanData) {
                const currentStatusLevel = statusLevel[currentSalesPlanData.status]
                if (currentStatusLevel) {
                    const nextStatus = levelToStatus[currentStatusLevel + 1];
                    if (nextStatus) {
                        setNextStatus(nextStatus)
                        setChangeStatusButtonVisible(true)
                    }
                    else {
                        setChangeStatusButtonVisible(false)
                        setNextStatus(null)
                    }
                }
            }
            else {
                setChangeStatusButtonVisible(false)
                setNextStatus(null)
            }

        }
    }, [currentSalesPlanId, data]);

    const menuButton = () => {
        return (
            <DropDownButton
                style={{margin: 5}}
                stylingMode={'text'}
                icon={'menu'}
                keyExpr={'text'}
                displayExpr={'text'}
                dataSource={[
                    {id: 1, text: 'Home', onClick: () => redirectTo('/')},
                    {id: 2, text: 'Forecast', onClick: () => redirectTo('/forecast')},
                    {id: 3, text: 'Logout', onClick: onLogout},
                ]}
                dropDownOptions={{
                    width: 100,
                }}
            />
        )
    }

    return (
        <Toolbar>
            <Item
                location={'before'}
                render={menuButton}
            />
            <Item
                location={'after'}
            >
                <Button
                    text={`Mark as ${nextStatus}`}
                    onClick={() => {
                        onChangeSalesPlanStatusButtonClick({id: currentSalesPlanId, status: nextStatus})
                    }}
                    visible={changeStatusButtonVisible}
                />
            </Item>
            <Item
                location={'after'}
            >
                <SelectBox
                    label={'Sales plan'}
                    labelMode={'floating'}
                    hint={'Choose sales plan'}
                    width={300}
                    dataSource={salesPlansList}
                    valueExpr={'id'}
                    displayExpr={item => item ? `${item?.title} (${item?.status})` : null}
                    itemRender={SalesPlanSelectorItem}
                    onOpened={refetch}
                    onValueChanged={e => {
                        dispatch(selectSalesPlan(e.value));
                        dispatch(deselectCompareSalesPlan());
                        setCurrentSalesPlanId(e.value);
                        onSalesPlanChanged(e);
                    }}
                />
            </Item>
            <Item
                location={'after'}
            >
                <SelectBox
                    label={'Level'}
                    labelMode={'floating'}
                    hint={'Select role'}
                    width={200}
                    dataSource={['Child', 'Parent']}
                    value={asinLevel}
                    onValueChanged={e => {
                        dispatch(setAsinLevel(e.value));
                        if (props.onAsinLevelChanged) {
                            props.onAsinLevelChanged(e.value);
                        }
                    }}
                />
            </Item>
        </Toolbar>
    );
}

export default Menu;
