import {Popup} from "devextreme-react/popup";
import {LoadPanel} from "devextreme-react/load-panel";
import {useState} from "react";

function ImportEntry(props) {
    const {account_id, account_name, import_url} = props;
    return <li><a href={import_url} target='_blank'>{account_name + ' (' + account_id + ')'}</a></li>;
}

function ImportEntryList(props) {
    const entries = props.entries;
    const elements = (entries !== null) ? (
        entries.map(item => (
            <ImportEntry
                key={item.account_id}
                account_id={item.account_id}
                account_name={item.account_name}
                import_url={item.import_url}
            />
        ))
    ) : null;

    return (
        <ul>
            {elements}
        </ul>
    )
}

export default function ExportToIPWindow(props) {
    const [loadContainer, setLoadContainer] = useState();

    const visible = props.visible;
    const content = props.content;
    const onHiding = props.onHiding;

    function onShow(e) {
        setLoadContainer(e.component.content());
    }

    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            onShown={onShow}
            width={400}
            height={250}
            shading={false}
            showCloseButton={true}
            showTitle={true}
            title={'Inventory Planner export list'}
        >
            <LoadPanel
                container={loadContainer}
                visible={content === null}
                shadingColor={"rgba(0,0,0,0.2)"}
                shading={true}
                showPane={true}
            />
            <ImportEntryList entries={content}/>
        </Popup>
    );
}
