import {Popup} from "devextreme-react/popup";
import {MinorTick, RangeSelector, Scale} from "devextreme-react/range-selector";
import {useDispatch, useSelector} from "react-redux";

import {forecastMonthToDate, formatForecastMonth} from "../../components/utils";
import {setMonthRange} from "./salesPlanGridSlice";

export default function MonthsChooser(props) {
    const dispatch = useDispatch();
    const [monthStart, monthEnd] = useSelector(state => state.salesPlanGrid.monthRange);
    const startValue = forecastMonthToDate(monthStart);
    const endValue = forecastMonthToDate(monthEnd);

    return (
        <Popup
            title={'Date range chooser'}
            visible={props.visible}
            height={150}
            width={500}
            resizeEnabled={false}
            showCloseButton={true}
            shading={false}
            closeOnOutsideClick={true}
            onHiding={props.onHiding}
        >
            <RangeSelector
                behavior={{animationEnabled: false}}
                value={{startValue, endValue}}
                onValueChange={({startValue, endValue}) => {
                    dispatch(setMonthRange([
                        formatForecastMonth(startValue.getFullYear(), startValue.getMonth() + 1),
                        formatForecastMonth(endValue.getFullYear(), endValue.getMonth() + 1),
                    ]));
                }}
            >
                <Scale
                    startValue={props?.monthRange?.[0] ?? new Date(2020, 0, 1)}
                    endValue={props?.monthRange?.[1] ?? new Date(2022, 11, 1)}
                    minorTickInterval={'month'}
                    tickInterval={'year'}
                    minRange={'month'}
                    label={{visible: false}}
                >
                    <MinorTick visible={true}/>
                </Scale>
            </RangeSelector>
        </Popup>
    )
}
