import get from "just-safe-get";
import omit from "just-omit";
import clone from "just-clone";
import pick from "just-pick";
import * as d3 from "d3-array";

import {formatForecastMonth} from "../components/utils";

const strategyName = 'inherit';
const keyColumns = ['marketplace', 'region', 'parent_product_id'];
const valueColumn = 'value';

export default class InheritModel {
    constructor(salesPlanEntity, salesPlan) {
        this.data = [];
        this.salesPlanEntity = salesPlanEntity;
        this.salesPlan = salesPlan;
        this.startMonth = formatForecastMonth(salesPlan.from_year, salesPlan.from_month);
        const entries = [];

        if (salesPlanEntity?.predictions) {
            salesPlanEntity.predictions.forEach(p => {
                get(p, ['strategy_parameters', strategyName], []).forEach(item => {
                    entries.push({
                        month: formatForecastMonth(p.year, p.month),
                        value: item[valueColumn],
                        ...Object.fromEntries(keyColumns.map(k => [k, item[k]])),
                    });
                });
            });

            this.data = d3.flatGroup(
                entries,
                ...keyColumns.map(k => (d => d[k])),
            ).map(row => ({
                ...Object.fromEntries(keyColumns.map((k, index) => [k, row[index]])),
                ...Object.fromEntries(row[keyColumns.length].map(i => [i.month, i.value])),
            }));
        }
    }

    applyEditData(editData) {
        const newData = clone(this.data);
        const editHandlers = {
            'update': editRow => {
                const newDataRow = newData.find(newDataRow => keyColumns.every(k => newDataRow[k] === editRow.key[k]));
                Object.assign(newDataRow, editRow.data);
            },
            'insert': editRow => {
                newData.push(omit(editRow.data, '__KEY__'));
            },
            'remove': editRow => {
                newData.splice(
                    newData.findIndex(newDataRow => keyColumns.every(k => newDataRow[k] === editRow.key[k])), 1
                );
            },
        };

        editData.forEach(editRow => editHandlers[editRow.type](editRow));
        return newData;
    }

    forecastForMonth(month, editData = null) {
        if (month < this.startMonth) {
            return this.salesPlanEntity.predictions.find(p => formatForecastMonth(p.year, p.month) === month).forecast;
        }

        const data = (editData ? this.applyEditData(editData) : this.data);
        const findForecast = row => {
            const entity = this.salesPlan.sales_plan_entities.find(
                e => e.product.id === row.parent_product_id
                    && e.marketplace === row.marketplace
                    && e.region === row.region
            );

            if (entity) {
                return entity.predictions.find(p => formatForecastMonth(p.year, p.month) === month).forecast;
            } else {
                console.warn('Parent sales plan entity not found');
                return 0;
            }
        };

        return data.reduce((subtotal, row) => (subtotal + findForecast(row) * (row?.[month] || 0)), 0);
    }

    calculateForecast(editData = null) {
        // TODO: change order in which the forecast is calculated - apply changes first, then calculate forecast
        return Object.fromEntries(this.salesPlanEntity.predictions.map(p => {
            const month = formatForecastMonth(p.year, p.month);
            return [month, this.forecastForMonth(month, editData)];
        }));
    }

    toSalesEntityFormat(editData=null) {
        const data = editData ? this.applyEditData(editData) : this.data;
        const forecast = this.calculateForecast(editData);

        const predictions = this.salesPlanEntity.predictions.map(p => {
            const month = formatForecastMonth(p.year, p.month);
            return {
                id: p.id,
                year: p.year,
                month: p.month,
                forecast: month <= this.salesPlanEntity.startMonth ? p.forecast : forecast[month],
                strategy_parameters: {
                    [strategyName]: data.map(row => ({
                        ...pick(row, keyColumns),
                        [valueColumn]: row[month],
                    }))
                },
            };
        });

        return {
            predictions,
            strategy_parameters: {},
        };
    }
}