import get from "just-safe-get";
import clone from "just-clone";

import {formatForecastMonth} from "../components/utils";

const strategyName = 'sales_history';
const drivers = [
    'Pricing',
    'Perfomance marketing',
    'SMM (influencers)',
    'External traffic',
    'Promo',
    'Bundles',
    'SEO (incl. boost specific keywords)',
    'Creatives & Content',
    'Reviews & Ratings and Returns',
    'Operations effiency',
    'Competition',
    'Corrections (out of stock, outliers, etc.)',
    'Other',
];

export default class SalesHistoryModel {
    constructor(salesPlanEntity, salesPlan) {
        this.data = [];
        this.base_forecast = {};
        this.salesPlanEntity = salesPlanEntity;
        this.startMonth = formatForecastMonth(salesPlan.from_year, salesPlan.from_month);

        const extractStrategyParamField = field => Object.fromEntries(
            salesPlanEntity.predictions.map(p => ([
                formatForecastMonth(p.year, p.month),
                get(p, ['strategy_parameters', strategyName, field], null)
            ]))
        );

        if (salesPlanEntity?.predictions) {
            drivers.forEach(driver => {
                const columns = extractStrategyParamField(driver);

                this.data.push({
                    metric: driver,
                    ...columns,
                });
            });

            Object.assign(this.base_forecast, extractStrategyParamField('base_forecast'));
        }
    }

    applyEditData(editData) {
        const newData = clone(this.data);

        editData.forEach(row => {
            const newDataRow = newData.find(newDataRow => newDataRow.metric === row.key);
            if (newDataRow) {
                Object.assign(newDataRow, row.data);
            } else {
                console.warn(`Unknown key (${row.key}) in the editData`);
            }
        });

        return newData;
    }

    forecastForMonth(month, editData = null) {
        if (month < this.startMonth) {
            return this.salesPlanEntity.predictions.find(p => formatForecastMonth(p.year, p.month) === month).forecast;
        }

        const data = (editData ? this.applyEditData(editData) : this.data);
        return this.base_forecast[month] * data.reduce((agg, row) => (agg + row[month]), 1);
    }

    calculateForecast(editData = null) {
        return Object.fromEntries(Object.keys(this.base_forecast).map(
            month => [month, this.forecastForMonth(month, editData)]
        ));
    }

    toSalesEntityFormat(editData = null) {
        const data = editData ? this.applyEditData(editData) : this.data;
        const forecast = this.calculateForecast(editData);

        const predictions = this.salesPlanEntity.predictions.map(p => {
            const month = formatForecastMonth(p.year, p.month);
            return {
                id: p.id,
                year: p.year,
                month: p.month,
                forecast: forecast[month],
                strategy_parameters: {
                    [strategyName]: {
                        base_forecast: this.base_forecast[month],
                        ...Object.fromEntries(data.map(row => [row.metric, row[month]])),
                    },
                },
            };
        });

        return {
            predictions,
            strategy_parameters: {},
        };
    }
}
