import {createContext, useContext} from "react";
import {useProductsByProductGroupId, useSalesPlanIdQuery} from "../../api/useAPI";
import {formatForecastMonth} from "../utils";

export const SalesPlanInfoContext = createContext({id: undefined});

export function useSalesPlanInfo() {
    const context = useContext(SalesPlanInfoContext);
    const {data: salesPlanData} = useSalesPlanIdQuery(context.id, {
        enabled: context.id !== undefined && context.id !== null,
        notifyOnChangeProps: ['data'],
    });

    // Data for product group related with the current sales plan
    const {data: productGroupData} = useProductsByProductGroupId(salesPlanData?.product_group?.id, {
        enabled: Boolean(salesPlanData),
        notifyOnChangeProps: ['data'],
    });

    return {
        salesPlanData,
        productGroupData,
        salesPlanId: context.id,
        fromYear: salesPlanData.from_year,
        fromMonth: salesPlanData.from_month,
        fromYearMonth: formatForecastMonth(salesPlanData.from_year, salesPlanData.from_month),
    }
}
