import React, {useRef} from "react";
import {SplitPane} from "react-collapse-pane";
import {Paper, Box} from "@mui/material";
import {ErrorBoundary} from "@sentry/react";

import SalesPlanGrid from "../features/SalesPlanGrid/SalesPlanGrid";
import {SalesPlanChart} from "../features/SalesPlanChart/SalesPlanChart";

import '../css/ForecastEditor.css';
import Fallback from "./Fallback";

function ForecastOverview(props) {
    const refChart = useRef();

    return (
        <SplitPane
            split={"vertical"}
            collapse={{
                collapseDirection: 'right',
            }}
            hooks={{
                onSaveSizes: () => refChart.current?.render(),
            }}
        >
            <Box m={1}>
                <Paper>
                    <ErrorBoundary fallback={Fallback}>
                        <SalesPlanGrid
                            bottomPadding={20}
                        />
                    </ErrorBoundary>
                </Paper>
            </Box>
            <Box m={1}>
                <Paper style={{padding: 10}}>
                    <ErrorBoundary fallback={Fallback}>
                        <SalesPlanChart
                            ref={refChart}
                        />
                    </ErrorBoundary>
                </Paper>
            </Box>
        </SplitPane>
    )
}

export default ForecastOverview;
