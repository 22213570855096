import {ArgumentAxis, Chart, CommonSeriesSettings, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useRef, useState} from "react";
import get from "just-safe-get";

import useSalesPlanEntity from "../../api/useSalesPlanEntity";
import {useSalesPlanEntities, salesEntityDataToView} from "../../api/useSalesPlanEntity";
import {formatForecastMonth} from "../../components/utils";
import clone from "just-clone";

export function SalesEntityChart(props) {
    const chartRef = useRef();
    const dataSource = [];

    const [seriesVisible, setSeriesVisibility] = useState({
        'Edited plan': true,
        'Current plan': true,
        'Compare plan': true,
    });
    const [seriesPresent, setSeriesPresent] = useState({
        'Edited plan': false,
        'Compare plan': false,
    });

    const {
        salesEntityId,
        salesEntityIds,
        compareEntityId,
        compareEntityIds,
        activeStrategy,
        editData,
        changedParent,
        isEditState,
        isParent
    } = useSelector(state => state.editMenu);

    const parentEdit = {
        manual: [
            {
                data: changedParent,
                key: 'Forecast',
                type: 'edit'
            }
        ]
    }

    const salesEntityRawData = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const compareEntityRawData = useCallback(useSalesPlanEntity, [compareEntityId])(compareEntityId).data;

    const salesEntityRawDataArray = useCallback(useSalesPlanEntities, [salesEntityIds])(salesEntityIds).data;
    const compareEntityRawDataArray = useCallback(useSalesPlanEntities, [compareEntityIds])(compareEntityIds).data;

    const changes = isParent ? parentEdit : editData
    let salesEntityData = {};
    let compareEntityData = {};

    function mergeEntities(entities) {
        if (!entities || !entities.length) {
            return {}
        }
        const data = clone(entities[0])
        let predictions = entities.reduce((acc, row) => {
            row.predictions?.forEach(prediction => {
                let accPrediction = acc.find(x => x.year === prediction.year && x.month === prediction.month);
                if (accPrediction) {
                    accPrediction.forecast = accPrediction.forecast + prediction.forecast;
                    if (!accPrediction.strategy_parameters) {
                        accPrediction['strategy_parameters'] = {};
                    }
                    if (!accPrediction.strategy_parameters.manual) {
                        accPrediction.strategy_parameters['manual'] = {};
                    }
                    const manualForecast = prediction.strategy_parameters?.manual?.Forecast
                    accPrediction.strategy_parameters.manual['Forecast'] = accPrediction.strategy_parameters.manual['Forecast'] + (prediction.forecast || 0)
                }
                else {
                    acc.push({
                        id: null,
                        year: prediction.year,
                        month: prediction.month,
                        forecast: prediction.forecast,
                        //strategy_parameters: clone(prediction.strategy_parameters)
                        strategy_parameters: {
                            manual: {
                                Forecast: prediction.forecast
                            }
                        }
                    })
                }
            })
            return acc
        }, [])
        data['predictions'] = predictions;
        const [fromYear, fromMonth] = data.startMonth ? data.startMonth.split('/') : [];
        return salesEntityDataToView(data, {fromYear: fromYear, fromMonth: fromMonth});
    }

    if (isParent) {
        salesEntityData = mergeEntities(salesEntityRawDataArray);
        compareEntityData = mergeEntities(compareEntityRawDataArray);
    }
    else {
        salesEntityData = salesEntityRawData
        compareEntityData = compareEntityRawData
    }

    const {strategies} = salesEntityData;

    if (Object.keys(salesEntityData).length > 0) {
        const selectedStrategy = activeStrategy || salesEntityData.active_strategy;
        const editedForecast = (changes[selectedStrategy] || Boolean(activeStrategy)
            ? strategies[selectedStrategy].calculateForecast(changes[selectedStrategy])
            : {});
        salesEntityData.predictions.forEach(p => dataSource.push({
                forecastDate: new Date(p.year, p.month - 1, 1),
                year: p.year,
                month: p.month,
                isFact: formatForecastMonth(p.year, p.month) < salesEntityData.startMonth,
                forecast: p.forecast,
                editForecast: get(editedForecast, formatForecastMonth(p.year, p.month), p.forecast),
                compareForecast: null,
                compareIsFact: null,
            })
        );
    }

    // let compareEntityData = compareEntityDataArray.find(entity => entity.id === salesEntityData.id) || {}

    if (Object.keys(compareEntityData).length > 0) {
        dataSource.forEach(entry => {
            const comparePrediction = compareEntityData.predictions.find(
                p => p.year === entry.year && p.month === entry.month
            );
            if (comparePrediction) {
                Object.assign(entry, {
                    compareForecast: comparePrediction.forecast,
                    compareIsFact: entry.forecastDate < comparePrediction.startMonth,
                });
            }
        })
    }




    // Set 'Edited plan' series present status
    useEffect(() => {
        setSeriesPresent(state => ({
            ...state,
            'Edited plan': isEditState,
        }));
    }, [isEditState]);

    // Set 'Compare plan' series preset status
    useEffect(() => {
        setSeriesPresent(state => ({
            ...state,
            'Compare plan': Object.keys(compareEntityData).length > 0,
        }));
    }, [compareEntityRawData, compareEntityRawDataArray]);

    // Re-render chart when opening the Edit menu to adapt it to the container size
    useEffect(() => {
        if (chartRef.current?.instance) {
            setTimeout(() => chartRef.current.instance.render(), 150);
        }
    }, [salesEntityId, salesEntityIds]);

    function stylizePoint(pt) {
        const factField = pt.seriesName === 'Compare plan' ? 'compareIsFact' : 'isFact';

        const planPointStyle = {
            color: 'white',
            border: {
                color: pt.series.getColor(),
                width: 2,
            },
            hoverStyle: {
                color: 'white',
                border: {
                    color: pt.series.getColor(),
                },
            }
        };

        return pt.data[factField] ? {} : planPointStyle;
    }

    function makeTooltip(pointInfo) {
        return {
            html: `<div style="text-align: center">
                ${pointInfo.seriesName}<br>
                ${pointInfo.argumentText}: <b>${pointInfo.valueText}</b>
                </div>`,
        };
    }

    return (
        <Chart
            ref={chartRef}
            dataSource={dataSource}
            animation={false}
            height={'100%'}
            width={'100%'}
            customizePoint={stylizePoint}
            onLegendClick={({target}) => {
                if (target.name === 'Compare plan') {
                    setSeriesVisibility(state => ({
                        ...state,
                        [target.name]: !seriesVisible[target.name],
                    }));
                }
            }}
        >
            <Tooltip
                enabled={true}
                zIndex={10000}
                argumentFormat={'y/MM'}
                format={'#0.#'}
                arrowLength={10}
                customizeTooltip={makeTooltip}
            />
            <ArgumentAxis
                argumentType={'datetime'}
                tick={{visible: true}}
                grid={{visible: true}}
                minorTickInterval={'month'}
                minorTick={{visible: true}}
                minorGrid={{visible: true}}
            />
            <ValueAxis
                showZero={true}
                minorTick={{visible: true}}
                minorGrid={{visible: true}}
            />
            <CommonSeriesSettings
                argumentField={'forecastDate'}
                type={'line'}
                point={{
                    size: 5,
                    border: {
                        visible: true,
                    }
                }}
            />
            <Series
                valueField={'editForecast'}
                type={'line'}
                name={'Edited plan'}
                visible={seriesPresent['Edited plan'] && seriesVisible['Edited plan']}
                dashStyle={'dash'}
                color={'green'}
            />
            <Series
                valueField={'forecast'}
                type={'line'}
                name={'Current plan'}
                visible={seriesVisible['Current plan']}
                color={'DeepSkyBlue'}
            />
            <Series
                valueField={'compareForecast'}
                type={'line'}
                name={'Compare plan'}
                visible={seriesPresent['Compare plan'] && seriesVisible['Compare plan']}
                color={'red'}
            />
        </Chart>
    );
}
