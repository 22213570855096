import {useMutation, useQuery, useQueryClient} from "react-query";
import clone from "just-clone";
import {
    getProductsByProductGroupId,
    getSalesPlanById,
    getSalesPlanParentById,
    getSalesPlans,
    getSalesEntityById,
    updateSalesPlanById,
    updateSalesPlanEntityById,
    recalculateSalesPlanPredictios,
} from "./api";


function getUpdatedObject(base, updates) {
    const result = clone(base);

    for (const key in updates) {
        if (updates.hasOwnProperty(key)) {
            const baseValue = base[key];
            const updatedValue = updates[key];

            if (isObject(baseValue) && isObject(updatedValue)) {
                result[key] = getUpdatedObject(baseValue, updatedValue);
            } else {
                result[key] = updatedValue !== undefined ? updatedValue : baseValue;
            }
        }
    }

    return result;
}

function isObject(value) {
    return value && typeof value === 'object' && !Array.isArray(value);
}


export const useSalesPlansQuery = (options) => useQuery(['sales_plans'], getSalesPlans, {
    refetchOnWindowFocus: false,
    ...options,
});


export const useSalesPlanIdQuery = (id, options) => useQuery(
    ['sales_plans', id],
    () => {
        if (!id) {
            // For some reason 'enabled' option does not work
            return new Promise(resolve => setTimeout(() => resolve()));
        }
        return getSalesPlanById(id);
    },
    {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        ...options,
    },
);

export const useSalesPlanIdForParentQuery = (id, options) => useQuery(
    ['sales_plans_with_parents', id],
    () => {
        if (!id) {
            // For some reason 'enabled' option does not work
            return new Promise(resolve => setTimeout(() => resolve()));
        }
        return getSalesPlanParentById(id);
    },
    {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        ...options,
    },
);


export const useProductsByProductGroupId = (product_group_id, options) => useQuery(
    ['product_group', product_group_id],
    () => getProductsByProductGroupId(product_group_id),
    {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 15,
        ...options,
    }
);

export const useSalesPlanIdUpdate = (salesPlanId, options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (updatedSalesPlan) => updateSalesPlanById(salesPlanId, updatedSalesPlan),
        {
            onMutate: async updatedSalesPlan => {
                const salesPlansQuery = 'sales_plans'
                await queryClient.cancelQueries(salesPlansQuery);
                const currentPlans = queryClient.getQueryData(salesPlansQuery);
                const updatedPlans = currentPlans.reduce((acc, plan) => {
                    acc.push(plan.id === salesPlanId ? updatedSalesPlan : plan)
                    return acc
                }, []);
                const currentPlan = queryClient.getQueryData([salesPlansQuery, updatedSalesPlan.id]);
                const updatedPlan = clone(updatedSalesPlan)
                if (!updatedPlan['sales_plan_entities'] || !updatedPlan['sales_plan_entities'].length) {
                    updatedPlan['sales_plan_entities'] = currentPlan['sales_plan_entities'];
                }

                queryClient.setQueryData([salesPlansQuery, updatedSalesPlan.id], updatedPlan);
                queryClient.setQueryData(salesPlansQuery, updatedPlans);

                return {
                    baseSalesPlans: currentPlans,
                    baseSalesPlan: currentPlan
                };
            },

            onError: (err, updatedSalesPlan, context) => {
                // Rollback optimistic update
                queryClient.setQueryData('sales_plans', context.baseSalesPlans);
                queryClient.setQueryData(['sales_plans', updatedSalesPlan.id], context.baseSalesPlan);
            },

            onSuccess: async (responseData, updatedSalesPlan, context) => {
                await queryClient.invalidateQueries('sales_plans');
                await queryClient.invalidateQueries(['sales_plans', updatedSalesPlan.id]);
            },

            ...options,
        }
    )
}

export const useSalesPlanEntityIdUpdate = (salesPlanEntityId, options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (updatedSalesPlanEntity) => updateSalesPlanEntityById(salesPlanEntityId, updatedSalesPlanEntity),
        {
            // Optimistic update of the sales plan
            onMutate: async updatedEntity => {
                const salesPlanQueryId = ['sales_plans', updatedEntity.sales_plan];
                await queryClient.cancelQueries(salesPlanQueryId);

                // Get sales plan data
                const {sales_plan_entities, ...salesPlanOther} = queryClient.getQueryData(salesPlanQueryId);
                // Set sales plan data from the updatedEntity
                const updatedSalesPlan = {
                    ...salesPlanOther,
                    sales_plan_entities: clone(sales_plan_entities.map(baseEntity => {
                        if (baseEntity.id === updatedEntity.id) {
                            return getUpdatedObject(baseEntity, updatedEntity);
                        }
                        return baseEntity;
                    })),
                };

                queryClient.setQueryData(salesPlanQueryId, updatedSalesPlan);
                queryClient.setQueryData(['sales_plan_entities', updatedEntity.id], updatedEntity);

                return {
                    // Save initial Entity to rollback changes in case of an error
                    baseEntity: sales_plan_entities.find(baseEntity => baseEntity.id === updatedEntity.id)
                };
            },

            onError: (err, updatedEntity, context) => {
                // Rollback optimistic update
                const salesPlanQueryId = ['sales_plans', updatedEntity.sales_plan];
                const {sales_plan_entities, ...salesPlanOther} = queryClient.getQueryData(salesPlanQueryId);
                queryClient.setQueryData(salesPlanQueryId, {
                    ...salesPlanOther,
                    sales_plan_entities: sales_plan_entities.map(
                        entity => entity.id === updatedEntity.id ? context.baseEntity : entity
                    ),
                });

                queryClient.setQueryData(['sales_plan_entities', updatedEntity.id], context.baseEntity);
            },

            onSuccess: (responseData, updatedEntity, context) => {
                queryClient.setQueryData(['sales_plan_entities', updatedEntity.id], updatedEntity);
            },

            ...options,
        },
    );
}

export const useSalesPlanEntityIdsUpdate = (salesPlanEntityIds, options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (updatedSalesPlanEntities) => {
            updatedSalesPlanEntities.map(x => updateSalesPlanEntityById(x.id, x))
        },
        {
            // Optimistic update of the sales plan
            onMutate: async updatedEntities => {
                if (!updatedEntities) {
                    return {}
                }
                const salesPlanQueryId = ['sales_plans', updatedEntities[0].sales_plan];
                await queryClient.cancelQueries(salesPlanQueryId);

                // Get sales plan data
                const {sales_plan_entities, ...salesPlanOther} = queryClient.getQueryData(salesPlanQueryId);
                // Set sales plan data from the updatedEntity
                const updatedSalesPlan = {
                    ...salesPlanOther,
                    sales_plan_entities: clone(sales_plan_entities.map(baseEntity => {
                        let updatedEntity = updatedEntities.find(entity => entity.id === baseEntity.id);
                        return updatedEntity ? getUpdatedObject(baseEntity, updatedEntity) : baseEntity;
                    })),
                };

                queryClient.setQueryData(salesPlanQueryId, updatedSalesPlan);

                updatedEntities.forEach(entity => {
                    queryClient.setQueryData(['sales_plan_entities', entity.id], clone(entity));
                })

                const updatedIds = updatedEntities.map(entity => entity.id);

                return {
                    // Save initial Entity to rollback changes in case of an error
                    baseEntities: sales_plan_entities.filter(baseEntity => updatedIds.includes(baseEntity.id))
                };
            },

            onError: (err, updatedEntities, context) => {
                if (!updatedEntities) {
                    return
                }
                // Rollback optimistic update
                const salesPlanQueryId = ['sales_plans', updatedEntities[0].sales_plan];
                const {sales_plan_entities, ...salesPlanOther} = queryClient.getQueryData(salesPlanQueryId);
                queryClient.setQueryData(salesPlanQueryId, {
                    ...salesPlanOther,
                    sales_plan_entities: sales_plan_entities.map(entity => {
                        let updatedEntity = updatedEntities.find(x => x.id === entity.id)
                        return updatedEntity ? context.baseEntities.find(baseEntity => baseEntity.id === entity.id) || entity : entity;
                    }),
                });

                updatedEntities.forEach(entity => {
                    queryClient.setQueryData(['sales_plan_entities', entity.id], context.baseEntities.find(baseEntity => baseEntity.id === entity.id));
                })
            },

            onSuccess: async (responseData, updatedEntities, context) => {
                for (let i = 0; i < updatedEntities.length; i++) {
                    let updatedEntity = updatedEntities[i];
                    await queryClient.invalidateQueries(['sales_plan_entities', updatedEntity.id]);
                    await queryClient.cancelQueries(['sales_plan_entities', updatedEntity.id]);
                    queryClient.setQueryData(['sales_plan_entities', updatedEntity.id], updatedEntity);
                }
            },

            ...options,
        },
    );
}

export const useSalesEntities = (salesEntityIds, options) => {
    return useQuery(
        ['sales_plan_entities', salesEntityIds],
         () => {
            const results = Promise.all(
                salesEntityIds.map(id => getSalesEntityById(id))
            );
            return results;
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            ...options,
        }
    );
};

export const useSalesEntityId = (salesEntityId, options) => {
    return useQuery(
        ['sales_plan_entities', salesEntityId],
        () => getSalesEntityById(salesEntityId),
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            ...options,
        }
    )
};

export const useRecalculateSalesPlanForecast = (salesPlanId, options) => {
    const queryClient = useQueryClient();

    return useMutation(
        () => recalculateSalesPlanPredictios(salesPlanId),
        {
            onSuccess: async (responseData) => {
                const salesPlanData = clone(queryClient.getQueryData(['sales_plans', salesPlanId]));

                // Find sales prediction within sales plan entity and update it's forecast
                const updateSalesPlanEntity = (item, salesPlanEntity) => {
                    const sales_prediction = salesPlanEntity.predictions.find(sp => sp.id === item.id);
                    if (sales_prediction) {
                        sales_prediction.forecast = item.forecast;
                    }
                };

                // Find sales plan entity within sales plan, then sales prediction within it and update forecast
                const updateSalesPlan = item => {
                    const salesPlanEntity = salesPlanData.sales_plan_entities.find(
                        spe => spe.id === item.sales_plan_entity
                    );

                    if (salesPlanEntity) {
                        updateSalesPlanEntity(item, salesPlanEntity);
                    } else {
                        console.log('Not found: ', item);
                    }
                };

                // Update sales plan query
                responseData.forEach(item => updateSalesPlan(item));
                queryClient.setQueryData(['sales_plans', salesPlanId], salesPlanData);

                // Invalidate individual sales plan entity queries
                const salesPlanEntityIds = new Set(responseData.map(i => i.sales_plan_entity));
                await queryClient.invalidateQueries({
                    predicate: q => q.queryKey[0] === 'sales_plan_entities' && salesPlanEntityIds.has(q.queryKey[1])
                });
            },
            ...options,
        },
    );
};
