import {useMemo} from "react";
import {useSelector} from "react-redux";
import clone from "just-clone";
import omit from "just-omit";

import {
    useSalesEntities,
    useSalesEntityId,
    useSalesPlanEntityIdsUpdate,
    useSalesPlanEntityIdUpdate,
    useSalesPlanIdQuery
} from "./useAPI";
import SalesHistoryModel from "../models/SalesHistoryModel";
import ManualModel, {ManualParentModel} from "../models/ManualModel";
import InheritModel from "../models/InheritModel";
import ReplacementPartModel from "../models/ReplacementPartModel";
import TotalSalesModel from "../models/TotalSalesModel";
import {formatForecastMonth} from "../components/utils";
import {getIsIPMappingChanged} from "../features/EditMenu/InventoryPlannerMapping/InventoryPlannerMappingSlice";

export function salesEntityDataToView(rawData, salesPlanData) {
    if (!rawData) {
        return {};
    }

    const {predictions, strategy_parameters: entityParameters, ...other} = rawData;

    return {
        ...other,
        predictions,
        months: predictions ? predictions.map(p => formatForecastMonth(p.year, p.month)) : [],
        strategies: {
            sales_history: new SalesHistoryModel(rawData, salesPlanData),
            manual: new ManualModel(rawData, salesPlanData),
            inherit: new InheritModel(rawData, salesPlanData),
            replacement_part: new ReplacementPartModel(rawData, salesPlanData),
            total_sales: new TotalSalesModel(rawData, salesPlanData),
        }
    }
}

function salesEntityParentDataToView(rawData, salesPlanData) {
    if (!rawData) {
        return {};
    }

    const {predictions, strategy_parameters: entityParameters, ...other} = rawData;

    return {
        ...other,
        predictions,
        months: predictions ? predictions.map(p => formatForecastMonth(p.year, p.month)) : [],
        strategies: {
            manual: new ManualParentModel(rawData, salesPlanData)
        }
    }
}

function viewToSalesEntityData(sourceData, activeStrategy = null, editData = null,
                               ipIsMappingChanged, ipAccountId, ipWarehouseId, ipVariantId) {
    const strategies = ['sales_history', 'manual', 'inherit', 'replacement_part', 'total_sales'];

    const selectedStrategy = activeStrategy || sourceData.active_strategy;
    const resultData = {
        ...clone(omit(sourceData, ['months', 'strategies', 'startMonth', 'months'])),
        strategy_parameters: {},
        active_strategy: selectedStrategy,
        sales_plan: sourceData?.sales_plan?.id || sourceData?.sales_plan,
        // predictions: [],
    };

    strategies.forEach(s => {
        if (editData[s] || s === selectedStrategy) {
            const strategyData = sourceData.strategies[s].toSalesEntityFormat(editData[s]);
            Object.assign(resultData.strategy_parameters, strategyData.strategy_parameters);

            if (resultData.predictions.length === 0) {
                resultData.predictions = strategyData.predictions;
            } else if (resultData.predictions.length !== strategyData.predictions.length) {
                console.error('Different predictions count for different strategies');
            } else {
                resultData.predictions.forEach(p => {
                    const strategyDataPrediction = strategyData.predictions.find(({id}) => id === p.id);
                    Object.assign(p.strategy_parameters, strategyDataPrediction.strategy_parameters);

                    if (s === selectedStrategy) {
                        p.forecast = strategyDataPrediction.forecast;
                    }
                });
            }
        }
    });

    if (ipIsMappingChanged) {
        resultData.inventory_planner_account = {id: ipAccountId};
        resultData.inventory_planner_warehouse = ipWarehouseId;
        resultData.inventory_planner_product = ipVariantId;
    }

    return resultData;
}

export function useSalesPlanEntities(ids) {
    // Get SalesPlanEntities
    const { data: rawData} = useSalesEntities(ids, {
        notifyOnChangeProps: ['data'],
        enabled: Array.isArray(ids) && ids.length > 0,
    });

    // Get SalesPlan
    const salesPlanId = rawData ? (rawData[0])?.sales_plan?.id || (rawData[0])?.sales_plan : undefined;
    const {data: salesPlan} = useSalesPlanIdQuery(salesPlanId, {
        notifyOnChangeProps: ['data'],
        enabled: Boolean(salesPlanId),
    });

    // Cook SalesPlanEntity
    const data = useMemo(() => rawData ? rawData.map(entity => salesEntityParentDataToView(entity, salesPlan)) : [], [rawData, salesPlan]);
    data.forEach(entity => {
        if (Object.keys(entity).length > 0) {
            entity.startMonth = salesPlan?.from_year ? formatForecastMonth(salesPlan.from_year, salesPlan.from_month) : null;
        }
    })

    return {
        data,
        rawData,
    }
}

export default function useSalesPlanEntity(id) {
    // Get SalesPlanEntity
    const {data: rawData} = useSalesEntityId(id, {
        notifyOnChangeProps: ['data'],
        enabled: Boolean(id),
    });

    // Get SalesPlan
    const salesPlanId = rawData?.sales_plan?.id || rawData?.sales_plan;
    const {data: salesPlan} = useSalesPlanIdQuery(salesPlanId, {
        notifyOnChangeProps: ['data'],
        enabled: Boolean(salesPlanId),
    });

    // Cook SalesPlanEntity
    const data = useMemo(() => salesEntityDataToView(rawData, salesPlan), [rawData, salesPlan]);
    if (Object.keys(data).length > 0) {
        data.startMonth = salesPlan?.from_year ? formatForecastMonth(salesPlan.from_year, salesPlan.from_month) : null;
    }

    return {
        data,
        rawData,
    }
}

export function useMutateSalesPlanEntity(id) {
    const {data} = useSalesPlanEntity(id);
    const {activeStrategy, editData} = useSelector(state => state.editMenu);
    const {accountId, warehouseId, variantId} = useSelector(state => state.inventoryPlannerMapping);
    const isIPMappingChanged = useSelector(getIsIPMappingChanged);
    const mutation = useSalesPlanEntityIdUpdate(id);

    const saveEditData = () => {
        const payload = viewToSalesEntityData(
                data,
                activeStrategy,
                editData,
                isIPMappingChanged,
                accountId,
                warehouseId,
                variantId,
            );

        mutation.mutate(payload);
    };

    return {
        saveEditData
    };
}

export function useMutateSalesPlanEntities(ids) {
    const rawData = useSalesPlanEntities(ids);
    const data = rawData.data;
    const {updatedChildren} = useSelector(state => state.editMenu);
    const mutation = useSalesPlanEntityIdsUpdate(ids);

    const saveEditData = () => {

        const payload = updatedChildren.map(edit => {
            return viewToSalesEntityData(
                data.find(x => x.id === edit.id),
                'manual',
                {
                    manual: [edit]
                },
                false,
                null,
                null,
                null,
            )
        });

        mutation.mutate(payload);
    };

    return {
        saveEditData
    };
}
