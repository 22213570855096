import {GoogleLogin} from "react-google-login";
import {Grid, Paper} from "@mui/material";

import {sendGoogleToken} from "../../api/auth";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_URL;


export default function Login(props) {
    const onSuccess = (googleUser) => {
        const idToken = googleUser.getAuthResponse().id_token;
        sendGoogleToken(idToken).then(props.onSuccess);
    }

    const onFailure = (response) => {
        console.log(response);
    }

    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{minHeight: '90vh'}}
        >
            <Grid item xs={3}>
                <Paper style={{padding: 50}}>
                    <GoogleLogin
                        clientId={clientId}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}
