import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import {Integrations} from "@sentry/tracing";
import {ReactQueryDevtools} from "react-query/devtools";
import {QueryClient, QueryClientProvider} from "react-query";

Sentry.init({
    dsn: "https://40a3a4489ca842139662ee17b8eb2541@o1029714.ingest.sentry.io/6036097",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        {/*<Sentry.ErrorBoundary fallback={<div>Something went terribly wrong...</div>} showDialog>*/}
            <QueryClientProvider client={queryClient}>
                <App/>
                <ReactQueryDevtools initialIsOpen={true}/>
            </QueryClientProvider>
        {/*</Sentry.ErrorBoundary>*/}
    </React.StrictMode>,
    document.getElementById('root')
);
