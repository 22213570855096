import {createContext, useEffect, useMemo, useRef, useState} from 'react';
import {Template} from "devextreme-react";
import {
    Column,
    DataGrid,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Scrolling,
    Selection,
    Sorting,
    Summary,
    TotalItem,
    Export,
} from "devextreme-react/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver";
import {useSelector, useDispatch} from "react-redux";
import get from "just-safe-get";
import {Button} from '@mui/material';

import EditMenu from "../EditMenu/EditMenu";
import CustomColumnChooser from "./ColumnChooser";
import MonthsChooser from "./MonthsChooser";
import UploadForecastMenu from "../UploadForecastMenu/UploadForecastMenu";
import {exportSalesPlanToInventoryPlanner} from "../../api/api";
import ExportToIPWindow from "../ExportToIPWindow/ExportToIPWindow";
import {
    useSalesPlanIdQuery,
    useSalesPlansQuery,
    useRecalculateSalesPlanForecast
} from "../../api/useAPI";
import {formatForecastMonth, getDeltaFormatter, makeMonthRange} from "../../components/utils";
import {selectCompareSalesPlan, setMonthRange, setSelectedRowKeys} from "./salesPlanGridSlice";
import {
    setSalesEntityId,
    removeSalesEntityId,
    resetEditState,
    setCompareEntityId,
    setSalesEntityIds,
    setIsParent
} from "../EditMenu/editMenuSlice";
import {useIsMutating} from "react-query";
import clone from "just-clone";



export const SalesPlanContext = createContext(undefined);

function getRowField(field, row, year, month) {
    if (row) {
        const prediction = row.predictions.find(p => p.year === year && p.month === month);
        if (prediction) {
            return prediction?.[field];
        }
    }
}

function getRowRate(field, row, rate_days) {
    if (row && row.run_rates) {
        const prediction = row.run_rates.find(p => p.rate_days === rate_days);
        if (prediction) {
            return prediction?.[field];
        }
    }
}

function getProductDiscontinuedValue(row) {
    if (row) {
        if (row.region !== 'US' || (row.marketplace && row.marketplace.toUpperCase() !== 'AMAZON')) {
            return null;
        }
        if (row.product.is_discontinued) {
            return 'Yes';
        }
        const asins = row.asins;
        if (asins) {
            return asins.some(asin => asin.is_discontinued) ? 'Yes' : 'No';
        }
    }
}

function getSpeStatusValue(row) {
    if (row) {
        if (row.status === 'Discontinued' || row.product.status === 'Discontinued') {
            return 'Discontinued';
        }
        else if (row.status === 'To be discontinued' || row.product.status === 'To be discontinued') {
            return 'To be discontinued';
        }
        return row.status || row.product.status;
    }
}

function compareRowGetter(compareSalesPlan) {
    const entities = compareSalesPlan?.sales_plan_entities;
    if (!entities) {
        return () => undefined;
    }

    const keyCols = ['marketplace', 'region', 'product.description', 'level'];
    return row => {
        return entities.find(
            e => keyCols.every(key => get(row, key) === get(e, key))
        );
    }
}

function productImage(data) {
    return (
        <img src={data.value} height={50} alt={''}/>
    );
}

function getColumns(salesPlan, compareSalesPlan, monthRange, showColumns, button_column = null) {
    function calculateSortValue(row, valueFunction) {
        const rowValue = valueFunction(row);
        if (row.level === 'child') {
            return valueFunction(row.parentData)
        }
        else {
            return rowValue
        }
    }

    function hasIpMapping(row) {
        Boolean(row.inventory_planner_account?.id) &&
        Boolean(row.inventory_planner_warehouse) &&
        Boolean(row.inventory_planner_product)
    }

    function getCompareEntityId(row) {
        if (compareSalesPlan?.sales_plan_entities) {
            return compareRowGetter(compareSalesPlan)(row)?.id
        }
    }

    const columnMonthRange = [new Date(2020, 0, 1), new Date(2022, 11, 1)];
    if (salesPlan?.sales_plan_entities) {
        columnMonthRange[0] = new Date(salesPlan.sales_plan_entities.reduce((min, entity) => Math.min(
            min,
            ...entity.predictions.map(p => new Date(p.year, p.month - 1, 1))
        ), columnMonthRange[0]));

        columnMonthRange[1] = new Date(salesPlan.sales_plan_entities.reduce((max, entity) => Math.max(
            max,
            ...entity.predictions.map(p => new Date(p.year, p.month - 1, 1))
        ), columnMonthRange[1]));
    }

    const chosenMonths = makeMonthRange(...columnMonthRange);
    const columns = [];
    const groupItems = [];
    const keyColumnOptions = {
        showWhenGrouped: true,
        fixed: true,
        allowGrouping: true,
        allowHeaderFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
    };

    const valueColumnOptions = {
        allowReordering: false,
        allowHeaderFiltering: false,
        allowSorting: true,
        showInColumnChooser: false,
        width: 75,
    };

    const greyBackgroundHeader = (caption) => ({
        caption: caption,
        headerCellRender: (headerProps) => (
            <th style={{ backgroundColor: '#f0f0f0' }}>
                {headerProps.column.caption}
            </th>
        ),
        cellRender: (props) => (
            <div style={{ backgroundColor: '#f0f0f0' }}>
                {props.value}
            </div>
        ),
    });

    const discontinuedCellRender = (caption) => ({
        caption: caption,
        cellRender: (props) => {
            const style = props.value === 'Yes' ? { color: 'red' } : {};
            return <span style={style}>{props.value}</span>;
        }
    });

    const statusCellRender = (caption) => ({
        caption: caption,
        cellRender: (props) => {
            let style = {};
            if (props.value === 'Discontinued') {
                style = { color: 'red' };
            }
            else if (props.value === 'To be discontinued') {
                style = { color: 'orange' };
            }
            return <span style={style}>{props.value}</span>;
        }
    });

    const getValueByPath = (path) => (row) => (
        path.split('.').reduce((value, key) => value?.[key], row)
    );

    const keyColumns = [{
        caption: 'Image',
        key: 'product_image_url',
        name: 'product_image_url',
        dataField: 'product.image_url',
        cellTemplate: 'renderProductImage',
        dataType: 'object',
        width: 80,
        fixed: true,
        allowGrouping: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        allowExporting: false,
        showInColumnChooser: true,
        visible: showColumns.includes('product_image_url'),
    }, {
        ...keyColumnOptions,
        dataField: 'marketplace',
        caption: 'Marketplace',
        key: 'marketplace',
        name: 'marketplace',
        dataType: 'string',
        width: 120,
        calculateSortValue: row => calculateSortValue(row, x => x.marketplace),
        visible: showColumns.includes('marketplace'),
    }, {
        ...keyColumnOptions,
        dataField: 'region',
        caption: 'Region',
        key: 'region',
        name: 'region',
        dataType: 'string',
        width: 80,
        calculateSortValue: row => calculateSortValue(row, x => x.region),
        visible: showColumns.includes('region'),
    }, {
        ...keyColumnOptions,
        caption: 'Product title',
        key: 'product_title',
        name: 'product_title',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.title'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.title')),
        width: 200,
        visible: showColumns.includes('product_title'),
    }, {
        ...keyColumnOptions,
        caption: 'Product ID',
        key: 'product_id',
        name: 'product_id',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.description'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.description')),
        width: 100,
        visible: showColumns.includes('product_id'),
    }, {
        ...keyColumnOptions,
        caption: 'Forecast strategy',
        dataField: 'active_strategy',
        key: 'active_strategy',
        name: 'active_strategy',
        dataType: 'string',
        width: 120,
        calculateSortValue: row => calculateSortValue(row, x => x.active_strategy),
        visible: showColumns.includes('active_strategy'),
    }, {
        ...keyColumnOptions,
        ...discontinuedCellRender('Discontinued'),
        key: 'product_is_discontinued',
        name: 'product_is_discontinued',
        dataType: 'string',
        calculateCellValue: getProductDiscontinuedValue,
        calculateSortValue: row => calculateSortValue(row, getProductDiscontinuedValue),
        width: 200,
        visible: showColumns.includes('product_is_discontinued'),
    }, {
        ...keyColumnOptions,
        ...statusCellRender('Status'),
        key: 'spe_status',
        name: 'spe_status',
        dataType: 'string',
        calculateCellValue: getSpeStatusValue,
        calculateSortValue: row => calculateSortValue(row, getSpeStatusValue),
        width: 200,
        visible: showColumns.includes('spe_status'),
    }, {
        ...keyColumnOptions,
        ...statusCellRender('Niche'),
        key: 'niche',
        name: 'niche',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.niche'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.niche')),
        width: 200,
        visible: showColumns.includes('niche'),
    }, {
        ...keyColumnOptions,
        ...statusCellRender('Subniche'),
        key: 'subniche',
        name: 'subniche',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.subniche'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.subniche')),
        width: 200,
        visible: showColumns.includes('subniche'),
    }, {
        ...keyColumnOptions,
        ...statusCellRender('Tag'),
        key: 'product_tag',
        name: 'product_tag',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.product_tag'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.product_tag')),
        width: 200,
        visible: showColumns.includes('product_tag'),
    }, {
        ...keyColumnOptions,
        caption: 'ASIN',
        key: 'asin',
        name: 'asin',
        dataType: 'string',
        calculateCellValue: row => row?.asins?.map(i => i.title).join(' / '),
        calculateSortValue: row => calculateSortValue(row, x => x?.asins?.map(i => i.title).join(' / ')),
        width: 120,
        visible: showColumns.includes('asin'),
    }, {
        ...keyColumnOptions,
        caption: 'SKU',
        key: 'sku',
        name: 'sku',
        dataType: 'string',
        calculateCellValue: row => row?.skus?.map(i => i.title).join(' / '),
        calculateSortValue: row => calculateSortValue(row, x => x?.skus?.map(i => i.title).join(' / ')),
        width: 120,
        visible: showColumns.includes('sku'),
    }, {
        ...keyColumnOptions,
        caption: 'Parent ASIN',
        key: 'parent',
        name: 'parent',
        dataType: 'string',
        calculateCellValue: getValueByPath('product.parent'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('product.parent')),
        width: 120,
        visible: showColumns.includes('parent'),
    }, {
        ...keyColumnOptions,
        caption: 'IP account',
        key: 'inventory_planner_account',
        name: 'inventory_planner_account',
        dataType: 'string',
        width: 120,
        calculateCellValue: getValueByPath('inventory_planner_account.name'),
        calculateSortValue: row => calculateSortValue(row, getValueByPath('inventory_planner_account.name')),
        visible: showColumns.includes('inventory_planner_account'),
    }, {
        ...keyColumnOptions,
        caption: 'IP warehouse',
        dataField: 'inventory_planner_warehouse',
        key: 'inventory_planner_warehouse',
        name: 'inventory_planner_warehouse',
        dataType: 'string',
        width: 120,
        calculateSortValue: row => calculateSortValue(row, x => x.inventory_planner_warehouse),
        visible: showColumns.includes('inventory_planner_warehouse'),
    }, {
        ...keyColumnOptions,
        caption: 'IP product',
        dataField: 'inventory_planner_product',
        key: 'inventory_planner_product',
        name: 'inventory_planner_product',
        dataType: 'string',
        width: 120,
        calculateSortValue: row => calculateSortValue(row, x => x.inventory_planner_product),
        visible: showColumns.includes('inventory_planner_product'),
    }, {
        ...keyColumnOptions,
        caption: 'Has IP mapping',
        key: 'ip_mapping_flg',
        name: 'ip_mapping_flg',
        dataType: 'boolean',
        trueText: 'Yes',
        falseText: 'No',
        calculateCellValue: hasIpMapping,
        calculateSortValue: row => calculateSortValue(row, hasIpMapping),
        width: 120,
        visible: showColumns.includes('ip_mapping_flg'),
    }, {
        ...keyColumnOptions,
        caption: 'Compare Sales Entity ID',
        key: 'compare_entity_id',
        name: 'compare_entity_id',
        dataType: 'string',
        calculateCellValue: getCompareEntityId,
        calculateSortValue: row => calculateSortValue(row, getCompareEntityId),
        visible: false,
        allowExporting: false,
        showInColumnChooser: false,
    }];

    const specialColumns = [{
        ...keyColumnOptions,
        calculateCellValue: () => '',
        caption: 'fake',
        key: 'fake',
        name: 'fake',
        dataType: 'string',
        showInColumnChooser: false,
        allowResizing: false,
        allowHiding: false,
        allowReordering: false,
        allowExporting: false,
        width: 0.01,
    }];

    if (button_column) {
        specialColumns.unshift({
            ...keyColumnOptions,
            type: 'buttons',
            key: 'custom_buttons',
            name: 'custom_buttons',
            allowReordering: false,
            allowResizing: false,
            allowExporting: false,
            showInColumnChooser: false,
            visible: salesPlan?.status !== 'APPROVED',
            ...button_column,
        });
    }

    const runRateMonthStr = (formatForecastMonth(salesPlan?.from_year, salesPlan?.from_month) ?? "") + " Run Rates";
    const runRateMonthIdStr = (formatForecastMonth(salesPlan?.from_year, salesPlan?.from_month) ?? "") + "_run_rates";
    const salesRunRateColumns = [{
        ...greyBackgroundHeader(runRateMonthStr),
        key: runRateMonthStr,
        name: runRateMonthStr,
        isBand: true,
        showInColumnChooser: false,
        visible: true,
        columns: [
            {
                ...valueColumnOptions,
                ...greyBackgroundHeader('30d'),
                rateDays: 30,
                key: runRateMonthIdStr + '_sales_rate_30d',
                name: runRateMonthIdStr + '_sales_rate_30d',
                dataType: 'number',
                calculateCellValue: row => getRowRate('forecast', row, 30),
                calculateSortValue: row => calculateSortValue(row, x => getRowRate('forecast', x, 30)),
                format: 'fixedPoint',
                visible: true,
            },
            {
                ...valueColumnOptions,
                ...greyBackgroundHeader('14d'),
                rateDays: 14,
                key: runRateMonthIdStr + '_sales_rate_14d',
                name: runRateMonthIdStr + '_sales_rate_14d',
                dataType: 'number',
                calculateCellValue: row => getRowRate('forecast', row, 14),
                calculateSortValue: row => calculateSortValue(row, x => getRowRate('forecast', x, 14)),
                format: 'fixedPoint',
                visible: true,
            },
            {
                ...valueColumnOptions,
                ...greyBackgroundHeader('7d'),
                rateDays: 7,
                key: runRateMonthIdStr + '_sales_rate_7d',
                name: runRateMonthIdStr + '_sales_rate_7d',
                dataType: 'number',
                calculateCellValue: row => getRowRate('forecast', row, 7),
                calculateSortValue: row => calculateSortValue(row, x => getRowRate('forecast', x, 7)),
                format: 'fixedPoint',
                visible: true,
            }
        ]
    }]

    const generateMonthColumns = (months) => {
        const monthColumns = [];

        months.forEach(m => {
            const monthStr = formatForecastMonth(m.year, m.month);
            const fromMonthStr = formatForecastMonth(salesPlan?.from_year || 2020, salesPlan?.from_month || 0);
            const compareRow = compareRowGetter(compareSalesPlan);

            function calculateDeltaCellValue(row) {
                const currVal = getRowField('forecast', row, m.year, m.month);
                const compVal = getRowField('forecast', compareRow(row), m.year, m.month);
                if (currVal != null && compVal != null) {
                    return currVal - compVal;
                }
            }

            function calculateDeltaPercentCellValue(row) {
                const currVal = getRowField('forecast', row, m.year, m.month);
                const compVal = getRowField('forecast', compareRow(row), m.year, m.month);
                if (currVal != null && compVal != null) {
                    return currVal / compVal - 1;
                }
            }

            monthColumns.push({
                caption: monthStr,
                key: monthStr,
                name: monthStr,
                isBand: true,
                showInColumnChooser: false,
                visible: monthStr >= monthRange[0] && monthStr <= monthRange[1],
                columns: [
                    {
                        ...valueColumnOptions,
                        caption: monthStr >= fromMonthStr ? 'Plan' : 'Fact',
                        key: monthStr + '_sales',
                        name: monthStr + '_sales',
                        dataType: 'number',
                        calculateCellValue: row => getRowField('forecast', row, m.year, m.month),
                        calculateSortValue: row => calculateSortValue(row, x => getRowField('forecast', x, m.year, m.month)),
                        format: 'fixedPoint',
                        visible: true,
                    }, {
                        ...valueColumnOptions,
                        caption: 'Δ',
                        key: monthStr + '_delta',
                        name: monthStr + '_delta',
                        dataType: 'number',
                        calculateCellValue: calculateDeltaCellValue,
                        calculateSortValue: row => calculateSortValue(row, calculateDeltaCellValue),
                        format: getDeltaFormatter(),
                        visible: Boolean(compareSalesPlan) && showColumns.includes('_delta'),
                    }, {
                        ...valueColumnOptions,
                        caption: 'Δ, %',
                        key: monthStr + '_delta_pcnt',
                        name: monthStr + '_delta_pcnt',
                        dataType: 'number',
                        calculateCellValue: calculateDeltaPercentCellValue,
                        calculateSortValue: row => calculateSortValue(row, calculateDeltaPercentCellValue),
                        format: getDeltaFormatter(true),
                        visible: Boolean(compareSalesPlan) && showColumns.includes('_delta_pcnt'),
                    }
                ],
            });
        });
        return monthColumns;
    }

    columns.push(...keyColumns, ...specialColumns, ...salesRunRateColumns, ...generateMonthColumns(chosenMonths));

    groupItems.push(
        ...salesRunRateColumns[0].columns.map(column => ({
            key: column.key,
            showInColumn: column.key,
            name: 'run_rate ' + column.rateDays,
            alignByColumn: true,
            summaryType: 'custom',
            displayFormat: '{0}',
            valueFormat: 'fixedPoint',
        })
    ))
    groupItems.push(
        ...chosenMonths.filter(
            m => formatForecastMonth(m.year, m.month) >= monthRange[0]
                && formatForecastMonth(m.year, m.month) <= monthRange[1]
        ).map(m => ([{
            key: formatForecastMonth(m.year, m.month) + '_sales',
            alignByColumn: true,
            summaryType: 'custom',
            name: 'forecast ' + m.year + ' ' + m.month,
            showInColumn: formatForecastMonth(m.year, m.month) + '_sales',
            displayFormat: '{0}',
            valueFormat: 'fixedPoint',
        }, {
            key: formatForecastMonth(m.year, m.month) + '_delta',
            column: formatForecastMonth(m.year, m.month) + '_delta',
            alignByColumn: true,
            summaryType: 'sum',
            displayFormat: '{0}',
            valueFormat: getDeltaFormatter(),
            // valueFormat: '+#,##0;-#,##0',
        }])).flat(),

        // Fix fake columns in place, to keep month totals from sliding over fixed columns
        {
            key: 'fake',
            column: 'fake',
            summaryType: 'count',
            alignByColumn: true,
        }
    );

    const columnComponents = columns.map(col => {
        if (col?.isBand) {
            const {columns: children, ...other} = col;
            return (
                <Column {...other}>
                    {children.map(child => <Column {...child}/>)}
                </Column>
            );
        } else {
            return (
                <Column {...col}/>
            );
        }
    });

    return {
        columns,
        columnComponents,
        groupItems,
        columnMonthRange,
    }
}

function SalesPlanGrid(props) {
    const refContainer = useRef();
    const refGrid = useRef();
    const dispatch = useDispatch();

    // Flag that DataGrid was repainted
    const [wasRepainted, setWasRepainted] = useState(false);

    // Query client
    const isMutating = useIsMutating();

    // Edited sales plan
    const salesPlanId = useSelector((state) => state.menu.salesPlanId);
    const asinLevel = useSelector((state) => state.menu.asinLevel);
    const [expandedRows, setExpandedRows] = useState([]);

    function getParentRowData(parentRow, fromYear, fromMonth) {
        const statusPriority = {
            null: 0,
            'discontinued': 1,
            'to be discontinued': 2,
            'active': 3
        }
        function getMaxStatus(a, b) {
            if (!a && !b) {
                return null
            }
            const aPriority = statusPriority[a?.toLowerCase() || null] || 0;
            const bPriority = statusPriority[b?.toLowerCase() || null] || 0;
            return aPriority > bPriority ? a : b;
        }
        let status = parentRow.children.reduce((acc, row) => {
            return getMaxStatus(acc, row.status);
        }, null)
        let mainChild = parentRow.children.sort((a, b) => {
            let aSales = a.predictions.find(x => x.year === fromYear && x.month === fromMonth)?.forecast || 0
            let bSales = b.predictions.find(x => x.year === fromYear && x.month === fromMonth)?.forecast || 0
            return aSales > bSales ? -1 : aSales < bSales
        })[0]
        let predictions = parentRow.children.reduce((acc, row) => {
            row.predictions?.forEach(prediction => {
                let accPrediction = acc.find(x => x.year === prediction.year && x.month === prediction.month);
                if (accPrediction) {
                    accPrediction.forecast = accPrediction.forecast + prediction.forecast;
                }
                else {
                    acc.push({
                        id: null,
                        year: prediction.year,
                        month: prediction.month,
                        forecast: prediction.forecast,
                        strategy_parameters: {}
                    })
                }
            })
            return acc
        }, [])
        let runRates = parentRow.children.reduce((acc, row) => {
            row.run_rates?.forEach(rate => {
                let accRate = acc.find(x => x.year === rate.year && x.month === rate.month && x.rate_days === rate.rate_days);
                if (accRate) {
                    accRate.forecast = accRate.forecast + rate.forecast;
                }
                else {
                    acc.push({
                        id: null,
                        year: rate.year,
                        month: rate.month,
                        rate_days: rate.rate_days,
                        forecast: rate.forecast,
                        strategy_parameters: {}
                    })
                }
            })
            return acc
        }, [])
        let asins = parentRow.children.reduce((acc, row) => {
            row.asins?.forEach(asin => {
                if (!acc['ids'].includes(asin.id)) {
                    acc['asins'].push(asin);
                }
            })
            return acc
        }, {asins: [], ids: []})['asins'].sort()
        let skus = parentRow.children.reduce((acc, row) => {
            row.skus?.forEach(sku => {
                if (!acc['ids'].includes(sku.id)) {
                    acc['skus'].push(sku);
                }
            })
            return acc
        }, {skus: [], ids: []})['skus'].sort()

        const parentKey = mainChild.product?.parent || mainChild.product?.description || '';
        const parentRowId = parentKey + '_' + String(mainChild.marketplace).toUpperCase() + '_' + String(mainChild.region).toUpperCase()
        const product = clone(mainChild.product)
        product['description'] = parentKey
        return {
            id: parentRowId,
            marketplace: mainChild.marketplace,
            region: mainChild.region,
            status: status,
            active_strategy: mainChild.active_strategy,
            strategy_parameters: mainChild.strategy_parameters,
            inventory_planner_account: mainChild.inventory_planner_account,
            inventory_planner_product: mainChild.inventory_planner_product,
            product: product,
            predictions: predictions,
            run_rates: runRates,
            asins: asins,
            skus: skus
        }
    }

    const groupData = (data, expandedRows) => {
        const grouped = (data?.sales_plan_entities || []).reduce((acc, row) => {
            const parentKey = row.product?.parent || row.product?.description || '';
            let parentRowId = parentKey + '_' + String(row.marketplace).toUpperCase() + '_' + String(row.region).toUpperCase()
            if (!acc[parentRowId]) {
                acc[parentRowId] = {
                    ...row,
                    children: []
                };
            }
            acc[parentRowId].children.push(row);
            return acc;
        }, {});

        let result = [];
        Object.values(grouped).forEach(parentRow => {
            let parentRowData = getParentRowData(parentRow, data.from_year, data.from_month);
            result.push({...parentRowData, level: 'parent', children: parentRow.children});
            if (expandedRows.includes(parentRowData.id)) {
                parentRow.children.forEach(child => result.push({...child, level: 'child', parentData: parentRowData}));
            }
        })
        return result;
    };

    const {data: currentPlanInitData, isLoading, isFetching} = useSalesPlanIdQuery(salesPlanId, {
        enabled: Boolean(salesPlanId),
        notifyOnChangeProps: ['data', 'isLoading', 'isFetching'],
    });

    // Sales plan to compare with
    const {comparePlanId, showColumns, monthRange} = useSelector((state) => state.salesPlanGrid);

    const {data: comparePlanList} = useSalesPlansQuery({notifyOnChangeProps: ['data']});
    const {data: comparePlanInitData, isLoading: isCompareLoading} = useSalesPlanIdQuery(comparePlanId, {
        enabled: Boolean(comparePlanId),
        notifyOnChangeProps: ['data', 'isLoading'],
    });

    // Recalculate sales plan
    const {mutate: recalculate, isLoading: isRecalculating} = useRecalculateSalesPlanForecast(salesPlanId, {
        enabled: Boolean(salesPlanId) && !isLoading && !isFetching,
    });

    const currentPlanEntities = useMemo(() => {
        if (asinLevel === 'Parent') {
            return groupData(currentPlanInitData, expandedRows);
        }
        return currentPlanInitData?.sales_plan_entities;
    }, [currentPlanInitData, asinLevel, expandedRows]);

    const comparePlanEntities = useMemo(() => {
        if (asinLevel === 'Parent') {
            return groupData(comparePlanInitData, expandedRows);
        }
        return comparePlanInitData?.sales_plan_entities;
    }, [comparePlanInitData, asinLevel, expandedRows]);

    const [currentPlanData, setCurrentPlanData] = useState(currentPlanInitData)
    const [comparePlanData, setComparePlanData] = useState(comparePlanInitData)

    useEffect(() => {
        if (currentPlanInitData) {
            setCurrentPlanData({...currentPlanInitData, sales_plan_entities: currentPlanEntities})
        }
    }, [currentPlanInitData, currentPlanEntities])

    useEffect(() => {
        if (comparePlanInitData) {
            setComparePlanData({...comparePlanInitData, sales_plan_entities: comparePlanEntities})
        }
    }, [comparePlanInitData, comparePlanEntities])

    // Memoize columns and groupItems so that it doesn't trigger DataGrid's re-render on each execution
    const {columns, columnComponents, groupItems, columnMonthRange} = useMemo(() => getColumns(
        currentPlanData,
        comparePlanData,
        monthRange,
        showColumns,
        {
            fixedPosition: 'left',
            width: 50,
            buttons: [{
                icon: 'edit',
                hint: 'Edit entry',
                onClick: showRowEditor,
            }],
        }
    ), [currentPlanData, comparePlanData, monthRange, showColumns]);

    const [editRow, setEditRow] = useState({
        key: null,
        data: null,
    });
    const [isPopupVisible, setPopupVisibility] = useState(false);

    // Choosers
    const [showColumnChooser, setShowColumnChooser] = useState(false);
    const [showMonthsChooser, setShowMonthsChooser] = useState(false);

    // Upload Forecast Menu
    const [showUploadForecastMenu, setShowUploadForecastMenu] = useState(false);

    // Export to IP window
    const [exportToIPWindowState, setExportToIPWindowState] = useState({
        visible: false,
        content: null,
    });

    // Show loading indicator while loading current sales plan
    useEffect(() => {
        const instance = refGrid.current.instance;
        if (!Boolean(currentPlanData) && Boolean(salesPlanId)) {
            instance.beginCustomLoading();
        }

        return () => instance.endCustomLoading();
    }, [currentPlanData, isFetching, salesPlanId]);

    // Change month range when current sales plan data is loaded
    useEffect(() => {
        if (currentPlanData) {
            const {from_year, from_month} = currentPlanData;
            const {
                year: endYear,
                month: endMonth
            } = currentPlanData.sales_plan_entities[0].predictions.reduce((pMax, p) => {
                if ((100 * pMax.year + pMax.month) > (100 * p.year + p.month)) {
                    return pMax;
                } else {
                    return p;
                }
            });

            const startYear = from_year - (from_month === 1);
            const startMonth = from_month === 1 ? 12 : from_month - 1;

            dispatch(setMonthRange([
                formatForecastMonth(startYear, startMonth),
                formatForecastMonth(endYear, endMonth),
            ]));
        }
    }, [currentPlanData]);

    // Handle popup editor
    function showRowEditor(e) {
        setEditRow(e.row);
        console.log('erow', e.row)

        if (e.row.data.level === 'parent') {
            dispatch(setSalesEntityIds(e.row.data.children.map(x => x.id)));
            dispatch(setIsParent(true));
        }
        else {
            dispatch(setSalesEntityId(e.row.key));
            dispatch(setIsParent(false));
        }

        if (comparePlanId) {
            const compareEntityId = compareRowGetter(comparePlanData)(e.row.data)?.id;
            dispatch(setCompareEntityId(compareEntityId));
        }

        setPopupVisibility(true);
    }

    function closeRowEditor() {
        setEditRow({key: null, data: null});
        dispatch(removeSalesEntityId());
        dispatch(resetEditState());
        setPopupVisibility(false);
    }

    // Export Inherit Forecast
    function onExportingInherit(exportType) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Inherit Forecast');
        const today = new Date();
        const currentYear = today.getFullYear()
        const currentMonth = today.getMonth()
        const todayFmt = today.getFullYear().toString()
            + (today.getMonth() + 1).toString().padStart(2, '0')
            + today.getDate().toString().padStart(2, '0');
        const planName = currentPlanData.title.replace(/ /g, '_') + '_inherit_params';
        let salesPlanEntities = []
        const products = []
        const forecastMonths = new Set()
        const rows = []
        currentPlanData['sales_plan_entities'].forEach((entity) => {
            if (entity['active_strategy'] === 'inherit' && exportType === 'all') salesPlanEntities.push(entity)
            products.push(entity['product'])
        })
        if (exportType === 'selective') {
            salesPlanEntities = refGrid.current.instance.getSelectedRowsData()
            if (salesPlanEntities.length === 0) salesPlanEntities = currentPlanData['sales_plan_entities']
        }
        salesPlanEntities.forEach((entity) => {
            const predictions = entity['predictions'].filter((p) => {
                return (p.year > currentYear || (p.year === currentYear && p.month > currentMonth))
            })
            const rowValues = {
                'marketplace': entity.marketplace,
                'region': entity.region,
                'product_id': entity.product.description,
                'active_strategy': entity.active_strategy
            }
            const rowValuesByParentProduct = {}
            predictions.forEach((prediction) => {
                const inheritSettings = prediction.strategy_parameters['inherit']
                if (inheritSettings) {
                    inheritSettings.forEach((inheritSetting) => {
                        const parentProductId = inheritSetting['parent_product_id'];
                        const dateKey = prediction.year.toString() + '/' + prediction.month.toString()
                        forecastMonths.add(dateKey)
                        if (parentProductId in rowValuesByParentProduct)
                            rowValuesByParentProduct[parentProductId][dateKey] = inheritSetting.value
                        else
                            rowValuesByParentProduct[parentProductId] = {
                                'parent_marketplace': inheritSetting['marketplace'],
                                'parent_region': inheritSetting['region'],
                                'parent_product_id': products.find(p => p.id === parentProductId).description,
                                [dateKey]: inheritSetting.value
                            }
                    })
                }
            })
            for (const key in rowValuesByParentProduct) {
                const row = Object.assign({}, rowValues, rowValuesByParentProduct[key])
                rows.push(row)
            }
        })

        worksheet.columns = [
            {header: 'marketplace', key: 'marketplace', width: 15},
            {header: 'region', key: 'region', width: 15},
            {header: 'product_id', key: 'product_id', width: 15},
            {header: 'active_strategy', key: 'active_strategy', width: 15},
            {header: 'parent_marketplace', key: 'parent_marketplace', width: 15},
            {header: 'parent_region', key: 'parent_region', width: 15},
            {header: 'parent_product_id', key: 'parent_product_id', width: 15}
        ].concat(
            Array.from(forecastMonths).map(forecastMonth => ({header: forecastMonth, key: forecastMonth, width: 10}))
        )
        worksheet.addRows(rows)
        worksheet.getRow(1).font = {bold: true}
        workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(
                new Blob([buffer], {type: 'application/octet-stream'}),
                todayFmt + '-' + planName + '.xlsx',
            );
        })
    }

    // Handle Export click
    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sales plan');

        const today = new Date();
        const todayFmt = today.getFullYear().toString()
            + (today.getMonth() + 1).toString().padStart(2, '0')
            + today.getDate().toString().padStart(2, '0');
        const planName = currentPlanData.title.replace(/ /g, '_');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            customizeCell: ({excelCell, gridCell}) => {
                if (gridCell.rowType === 'totalFooter') {
                    excelCell.value = undefined;
                } else if (gridCell.rowType === 'group') {
                    if (gridCell?.groupSummaryItems?.length > 0) {
                        excelCell.value = gridCell.groupSummaryItems[0].value;
                        excelCell.numFmt = '#,##0'
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(
                    new Blob([buffer], {type: 'application/octet-stream'}),
                    todayFmt + '-' + planName + '.xlsx',
                );
            })
        })

        e.cancel = true;
    }

    // Handle Export to IP click
    function exportToIP(salesPlanId) {
        setExportToIPWindowState({
            visible: true,
            content: null,
        })

        exportSalesPlanToInventoryPlanner(
            salesPlanId
        ).catch(
            e => console.error('Something went wrong: ', e)
        ).then(r => {
            setExportToIPWindowState({
                visible: true,
                content: r,
            });
        })
    }

    // Resize grid when window is resized
    useEffect(() => {
        const setGridHeight = () => {
            const {top} = refContainer.current.getBoundingClientRect();
            refGrid.current?.instance?.option('height', window.innerHeight - top - props.bottomPadding);
        };

        setGridHeight();
        window.addEventListener('resize', setGridHeight);
        return () => window.removeEventListener('resize', setGridHeight);
    }, [props.bottomPadding]);

    // Repaint DataGrid when dataSource changes (fix header row bug)
    useEffect(() => {
        setWasRepainted(false);
    }, [currentPlanData, showColumns]);

    useEffect(() => {
        setExpandedRows([]);
    }, [salesPlanId, asinLevel])

    const handleRowExpand = (parentKey) => {
        setExpandedRows(prev =>
            prev.includes(parentKey)
                ? prev.filter(row => row !== parentKey)
                : [...prev, parentKey]
        );
    };

    const renderButtonCell = (rowData) => {
        const isExpanded = expandedRows.includes(rowData.id);
        return (
            <Button onClick={() => rowData.level === 'parent' ? handleRowExpand(rowData.id) : {}}>
                {rowData.level === 'parent' ? (isExpanded ? '-' : '+') : ''}
            </Button>
        );
    };

    const rowPrepared = (e) => {
        if (e.rowType === "data" && e.data.level === 'child') {
            e.rowElement.classList.add("child-row");
        }
    }

    const cellPrepared = (e) => {
        if (e.rowType === "data" && e.data.level === 'child') {
            e.cellElement.classList.add("child-row");
        }
    }

    const onCustomizeSummary = (options) => {
        let sumParams = options.name.split(" ")
        if (sumParams[0] === 'forecast') {
            let year = parseInt(sumParams[1])
            let month = parseInt(sumParams[2])
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            }
            if (options.summaryProcess === 'calculate') {
                if (options.value.level !== 'child') {
                    let prediction = options.value.predictions.find(x => x.year === year && x.month === month)?.forecast;
                    if (prediction) {
                        options.totalValue += prediction;
                    }
                }
            }
        }
        else if (sumParams[0] === 'run_rate') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            }
            if (options.summaryProcess === 'calculate') {
                let rateDays = parseInt(sumParams[1])
                if (options.value.level !== 'child') {
                    if (options.value.run_rates && options.value.run_rates.length > 0) {
                        let rate = options.value.run_rates.find(x => x.rate_days === rateDays)?.forecast;
                        if (rate) {
                            options.totalValue += rate;
                        }
                    }
                }
            }
        }
    };

    return (
        <div ref={refContainer}>
            <SalesPlanContext.Provider value={props.salesPlanId}>
                <DataGrid
                    ref={refGrid}
                    keyExpr={'id'}
                    showBorders={true}
                    paging={{enabled: false}}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    dataSource={currentPlanData?.sales_plan_entities}
                    onSelectionChanged={e => dispatch(setSelectedRowKeys(e.selectedRowKeys))}
                    onExporting={onExporting}
                    onRowPrepared={rowPrepared}
                    onCellPrepared={cellPrepared}
                    onContentReady={e => {
                        if (!wasRepainted) {
                            setTimeout(() => {
                                console.log('Repainting');
                                e.component.repaint();
                                setWasRepainted(true);
                            })
                        }
                    }}
                    toolbar={{
                        items: [
                            'groupPanel',
                            {
                                widget: 'dxLoadIndicator',
                                location: 'after',
                                width: 30,
                                options: {
                                    height: 15,
                                    width: 15,
                                    visible: isCompareLoading,
                                },
                            },
                            {
                                widget: 'dxSelectBox',
                                location: 'after',
                                options: {
                                    value: comparePlanId,
                                    dataSource: comparePlanList?.filter(
                                        i => i.product_group.id === currentPlanData?.product_group?.id
                                    ),
                                    displayExpr: 'title',
                                    valueExpr: 'id',
                                    placeholder: 'Compare with...',
                                    width: 220,
                                    disabled: !Boolean(salesPlanId),
                                    showClearButton: true,
                                    onValueChanged: e => dispatch(selectCompareSalesPlan(e.value)),
                                },
                            },
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'columnchooser',
                                    onClick: () => setShowColumnChooser(true),
                                },
                            },
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'event',
                                    onClick: () => setShowMonthsChooser(true),
                                }
                            },
                            {
                                name: 'exportButton',
                                location: 'after',
                            },
                            {
                                widget: 'dxDropDownButton',
                                location: 'after',
                                options: {
                                    icon: 'preferences',
                                    displayExpr: 'text',
                                    dropDownOptions: {
                                        width: '275px',
                                    },
                                    items: [{
                                        icon: 'refresh',
                                        text: 'Recalculate forecast',
                                        disabled: !Boolean(salesPlanId) || isMutating || isFetching,
                                        onClick: () => recalculate(),
                                    }, {
                                        icon: 'upload',
                                        text: 'Upload forecast',
                                        disabled: !Boolean(salesPlanId) || isMutating || isFetching,
                                        onClick: () => setShowUploadForecastMenu(true),
                                    }, {
                                        icon: 'exportxlsx',
                                        text: 'Download inherit forecast',
                                        disabled: !Boolean(salesPlanId) || isMutating || isFetching,
                                        onClick: () => onExportingInherit('all'),
                                    }, {
                                        icon: 'exportselected',
                                        text: 'Download inherit forecast (selected rows)',
                                        disabled: !Boolean(salesPlanId) || isMutating || isFetching,
                                        onClick: () => onExportingInherit('selective'),
                                    }, {
                                        icon: 'export',
                                        text: 'Export forecast to Inventory Planner',
                                        disabled: !Boolean(salesPlanId) || isMutating || isFetching,
                                        onClick: () => exportToIP(salesPlanId),
                                    }],
                                },
                            },
                        ]
                    }}
                >
                    <Column
                        dataField={'extend_buttons'}
                        caption={''}
                        width={50}
                        cellRender={({data}) => renderButtonCell(data)}
                        visible={asinLevel === 'Parent'}
                        fixed={true}
                    />
                    {columnComponents}
                    <Sorting mode={'multiple'}/>
                    <Selection
                        mode="multiple"
                        allowSelectAll={true}
                        showCheckBoxesMode={'always'}
                    />
                    <HeaderFilter
                        visible={true}
                        allowSearch={true}
                    />
                    <Grouping
                        autoExpandAll={false}
                        contextMenuEnabled={true}
                    />
                    <GroupPanel visible={true}/>
                    <Summary calculateCustomSummary={onCustomizeSummary}>
                        {groupItems.map(i => <GroupItem {...i}/>)}
                        {groupItems.map(i => <TotalItem {...i}/>)}
                    </Summary>
                    {/* TODO: check if bug-fix is released */}
                    <Scrolling
                        mode={'virtual'}
                        showScrollbar='always'
                        columnRenderingMode='standard'
                    />
                    <Template name={'renderProductImage'} render={productImage}/>
                    <Export
                        enabled={true}
                        allowExportSelectedData={true}
                    />
                </DataGrid>
                <EditMenu
                    editRow={editRow}
                    visible={isPopupVisible}
                    onHiding={closeRowEditor}
                    // TODO: fix issue with editing and closing popup, when setEditRow is called in onHidden
                    // Setting this separately to make transition smoother
                    // onHidden={() => setEditRow({key: null, data: null})}
                />
                <CustomColumnChooser
                    visible={showColumnChooser}
                    columns={[
                        ...columns.filter(col => col.showInColumnChooser),
                        {key: '_delta', caption: 'Δ'},
                        {key: '_delta_pcnt', caption: 'Δ, %'},
                    ]}
                    onHiding={() => setShowColumnChooser(false)}
                />
                <MonthsChooser
                    visible={showMonthsChooser}
                    onHiding={() => setShowMonthsChooser(false)}
                    monthRange={columnMonthRange}
                />
                <UploadForecastMenu
                    visible={showUploadForecastMenu}
                    onHiding={() => setShowUploadForecastMenu(false)}
                    salesPlanId={salesPlanId}
                />
                <ExportToIPWindow
                    visible={exportToIPWindowState.visible}
                    onHiding={() => setExportToIPWindowState({visible: false, content: null})}
                    content={exportToIPWindowState.content}
                />
            </SalesPlanContext.Provider>
        </div>
    )
}

export default SalesPlanGrid;