import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState} from "react";
import {Lookup} from "devextreme-react";
import {LoadPanel} from "devextreme-react/load-panel";
import {Box, Item} from "devextreme-react/box";

import {useIPAccounts, useVariants, useWarehouses} from "../../../api/useInventoryPlanner";
import {setAccountId, setWarehouseId, setVariantId} from "./InventoryPlannerMappingSlice";

import './InventoryPlannerMapping.css';


function getLookupClasses(isEdited) {
    const classes = ['dx-field-value', 'ip-settings-field-value'];
    if (isEdited) {
        classes.push('dx-field-value-edit');
    }

    return classes.join(' ');
}


function renderVariantField(data) {
    return (
        <Box direction={'row'}>
            <Item baseSize={50} ratio={0} shrink={0}>
                <img src={data?.image} alt={''} style={{maxWidth: '50px', maxHeight: '50px'}}/>
            </Item>
            <Item baseSize={200} ratio={1} shrink={1}>
                <div className={'ip-variant-item'}>
                    <div className={'ip-variant-item-label'}>SKU</div>
                    <div className={'ip-variant-item-value'}>{data?.sku}</div>
                </div>
                <div className={'ip-variant-item'}>
                    <div className={'ip-variant-item-label'}>ASIN</div>
                    <div className={'ip-variant-item-value'}>{data?.asin}</div>
                </div>
                <div className={'ip-variant-item'}>
                    <div className={'ip-variant-item-label'}>ID</div>
                    <div className={'ip-variant-item-value'}>{data?.id}</div>
                </div>
            </Item>
        </Box>
    );
}


function renderVariantItem(data) {
    const fieldsetClasses = ['ip-variant-item-fieldset'];
    const legend = (
        <legend className={'ip-variant-item-fieldset-legend'}>Probable match</legend>
    );

    if (data.isMatch) {
        fieldsetClasses.push('ip-variant-item-fieldset-match');
    }

    return (
        <fieldset className={fieldsetClasses.join(' ')}>
            {data.isMatch ? legend : null}
            {renderVariantField(data)}
        </fieldset>
    );
}


function InventoryPlannerAccountList(props) {
    const dispatch = useDispatch();
    const {data: accounts, isLoading} = useIPAccounts();
    const accountId = useSelector(state => state.inventoryPlannerMapping.accountId);
    const defaultAccountId = useSelector(state => state.inventoryPlannerMapping.defaultAccountId);

    return (
        <Lookup
            className={getLookupClasses(accountId !== defaultAccountId)}
            dataSource={accounts}
            value={accountId}
            onValueChanged={e => dispatch(setAccountId(e.value))}
            valueExpr={'id'}
            displayExpr={'name'}
            showClearButton={true}
            width={300}
            disabled={isLoading}
        />
    );
}


function InventoryPlannerWarehouseList(props) {
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.inventoryPlannerMapping.accountId);
    const warehouseId = useSelector(state => state.inventoryPlannerMapping.warehouseId);
    const defaultWarehouseId = useSelector(state => state.inventoryPlannerMapping.defaultWarehouseId);

    const {data: warehouses, isLoading} = useWarehouses(accountId, {
        enabled: !!accountId,
    });

    useEffect(() => {
        if (warehouses) {
            if (warehouses.find(wh => wh.id === defaultWarehouseId)) {
                dispatch(setWarehouseId(defaultWarehouseId));
            } else {
                dispatch(setWarehouseId(null));
            }
        }
    }, [accountId, warehouses]);

    return (
        <Lookup
            className={getLookupClasses(warehouseId !== defaultWarehouseId)}
            dataSource={warehouses != null ? warehouses : []}
            value={warehouseId}
            onValueChanged={e => dispatch(setWarehouseId(e.value))}
            valueExpr={'id'}
            displayExpr={'display_name'}
            searchEnabled={true}
            searchExpr={['id', 'display_name']}
            searchMode={'contains'}
            showClearButton={true}
            width={300}
            disabled={isLoading || !Boolean(warehouses)}
        />
    );
}


function InventoryPlannerVariantList({product, ...props}) {
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.inventoryPlannerMapping.accountId);
    const warehouseId = useSelector(state => state.inventoryPlannerMapping.warehouseId);
    const variantId = useSelector(state => state.inventoryPlannerMapping.variantId);
    const defaultVariantId = useSelector(state => state.inventoryPlannerMapping.defaultVariantId);

    const [dataSource, setDataSource] = useState([]);

    const {data: variants, isLoading} = useVariants(accountId, warehouseId, {
        enabled: !!accountId && !!warehouseId,
    });

    function checkProductVariant(variant) {
        if (!product) {
            return false;
        }

        const checkOne = (pParam, vParam) => {
            if (!pParam || !vParam) {
                return false;
            }

            return pParam.includes(vParam) || vParam.includes(pParam);
        };

        return (
            product.skus.some(item => checkOne(item.title, variant?.sku)) ||
            product.asins.some(item => checkOne(item.title, variant?.asin))
        );
    }

    useEffect(() => {
        if (variants) {
            const newDataSource = variants.map(v => ({
                ...v,
                isMatch: checkProductVariant(v),
            }));

            newDataSource.sort((a, b) => {
                if (a.isMatch > b.isMatch) return -1;
                else if (a.isMatch < b.isMatch) return 1;
                else return 0;
            });

            setDataSource(newDataSource);
        }
    }, [product, variants]);

    useEffect(() => {
        if (variants) {
            if (variants.find(v => v.id === defaultVariantId)) {
                dispatch(setVariantId(defaultVariantId));
            } else {
                dispatch(setVariantId(null));
            }
        }
    }, [warehouseId, variants]);

    return (
        <Lookup
            className={getLookupClasses(variantId !== defaultVariantId)}
            dataSource={dataSource}
            value={variantId}
            onValueChanged={e => dispatch(setVariantId(e.value))}
            valueExpr={'id'}
            displayExpr={'id'}
            showClearButton={true}
            searchEnabled={true}
            cleanSearchOnOpening={false}
            searchMode={'contains'}
            searchExpr={['id', 'sku', 'asin']}
            disabled={isLoading || !Boolean(variants)}
            itemRender={renderVariantItem}
            fieldRender={renderVariantField}
            width={300}
            height={'auto'}
        />
    );
}


export default function InventoryPlannerMapping({data, ...props}) {
    const {accountId, warehouseId} = useSelector(state => state.inventoryPlannerMapping);
    const {isLoading: isAccountsLoading} = useIPAccounts({enabled: false});
    const {isLoading: isWarehousesLoading} = useWarehouses(accountId, {enabled: false});
    const {isLoading: isVariantsLoading} = useVariants(accountId, warehouseId, {enabled: false});

    return (
        <Fragment>
            <div
                id={'ip-mapping-editor'}
                className={'dx-fieldset'}
                style={{height: '100%', margin: '0px', width: 'fit-content', padding: '10px'}}
            >
                <div className={'dx-field'}>
                    <div className={'dx-field-label ip-settings-field-label'}>
                        Account
                    </div>
                    <InventoryPlannerAccountList/>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-label ip-settings-field-label'}>
                        Warehouse
                    </div>
                    <InventoryPlannerWarehouseList/>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-label ip-settings-field-label'}>
                        Product
                    </div>
                    <InventoryPlannerVariantList product={data?.product}/>
                </div>
            </div>
            <LoadPanel
                container={'#ip-mapping-editor'}
                position={{my: 'center', at: 'center', of: '#ip-mapping-editor'}}
                shading={true}
                showPane={true}
                showIndicator={true}
                visible={isAccountsLoading || isWarehousesLoading || isVariantsLoading}
                shadingColor={'rgba(0,0,0,0.2)'}
            />
        </Fragment>
    );
}
