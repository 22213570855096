import get from "just-safe-get";
import clone from "just-clone";
import omit from "just-omit";

import {formatForecastMonth, parseForecastMonth} from "../components/utils";

const strategyName = 'total_sales';
const keyColumns = ['marketplace'];

export default class TotalSalesModel {
    constructor(salesPlanEntity, salesPlan) {
        this.data = [];
        this.salesPlanEntity = salesPlanEntity;
        this.salesPlan = salesPlan;
        this.startMonth = formatForecastMonth(salesPlan.from_year, salesPlan.from_month);

        if (salesPlanEntity) {
            this.data.push(...get(salesPlanEntity, ['strategy_parameters', strategyName], []));
        }
    }

    applyEditData(editData) {
        const newData = clone(this.data);
        const editHandlers = {
            'update': editRow => {
                const newDataRow = newData.find(newDataRow => keyColumns.every(k => newDataRow[k] === editRow.key[k]));
                Object.assign(newDataRow, editRow.data);
            },
            'insert': editRow => {
                newData.push(omit(editRow.data, '__KEY__'));
            },
            'remove': editRow => {
                newData.splice(
                    newData.findIndex(newDataRow => keyColumns.every(k => newDataRow[k] === editRow.key[k])), 1
                );
            },
        };

        editData.forEach(editRow => editHandlers[editRow.type](editRow));
        return newData;
    }

    forecastForMonth(forecastMonth, editData = null) {
        const [year, month] = parseForecastMonth(forecastMonth);
        const data = (editData ? this.applyEditData(editData) : this.data);

        const findForecast = (row, shifted_year, shifted_month) => {
            let totalForecast = 0;

            const entities = this.salesPlan.sales_plan_entities.filter(
                e => e.marketplace === row.marketplace
            );

            if (entities.length > 0) {
                entities.forEach(entity => {
                    const prediction = entity.predictions.find(p => p.year === shifted_year && p.month === shifted_month);
                    if (prediction) {
                        totalForecast += prediction.forecast;
                    } else {
                        console.warn('Requested month not found for some entity');
                    }
                });
                return totalForecast;
            } else {
                console.warn('Sales plan entities not found for the marketplace');
                return 0;
            }
        };

        return data.reduce((subtotal, row) => {
            const months_shift = row?.['months_shift'] || 0;
            const shifted_month = ((month - months_shift - 1) % 12 + 12) % 12 + 1;
            const shifted_year = year + Math.floor((month - months_shift - 1) / 12);

            return subtotal + findForecast(row, shifted_year, shifted_month) * (row?.['sales_share'] || 0);
        }, 0);
    }

    calculateForecast(editData = null) {
        // TODO: change order in which the forecast is calculated - apply changes first, then find parent forecasts,
        //  then calculate current forecast
        const data = (editData ? this.applyEditData(editData) : this.data);
        const result = Object.fromEntries(this.salesPlanEntity.predictions.map(
            p => [
                formatForecastMonth(p.year, p.month),
                formatForecastMonth(p.year, p.month) < this.startMonth ? p.forecast : 0
            ]
        ));

        const findParentEntities = row => {
            return this.salesPlan.sales_plan_entities.filter(
                e => e.marketplace === row.marketplace
                    && (e.id !== this.salesPlanEntity.id)
            );
        };

        data.forEach(row => {
            const entities = findParentEntities(row);
            entities.forEach(entity => {
                const months_shift = row?.['months_shift'] || 0;
                Object.keys(result).forEach(forecastMonth => {
                    if (forecastMonth >= this.startMonth) {
                        const [year, month] = parseForecastMonth(forecastMonth);
                        const shifted_month = ((month - months_shift - 1) % 12 + 12) % 12 + 1;
                        const shifted_year = year + Math.floor((month - months_shift - 1) / 12);

                        const prediction = entity.predictions.find(
                            p => p.year === shifted_year && p.month === shifted_month
                        );

                        result[forecastMonth] += (prediction?.forecast || 0) * (row?.['sales_share'] || 0);
                    }
                });
            });
        });

        return result;
    }

    toSalesEntityFormat(editData = null) {
        const data = editData ? this.applyEditData(editData) : this.data;
        const forecast = this.calculateForecast(editData);

        const strategy_parameters = {
            [strategyName]: data,
        };

        const predictions = this.salesPlanEntity.predictions.map(p => {
            const month = formatForecastMonth(p.year, p.month);
            return {
                id: p.id,
                year: p.year,
                month: p.month,
                forecast: forecast[month],
            };
        });

        return {strategy_parameters, predictions};
    }

}
